.locator {
	display: flex;
	flex-direction: column;
}

.locator__inner {
	order: 1;
}

.locator__map {
	order: 2;
}

.locator-results {
	max-height: 25vh;
	overflow-y: auto;
}

@media only screen and (min-width: 800px) {
	.locator {
		display: block;
	}
}


.locator {
	position: relative;
	margin-top: 15px;
}

.locator__map {
	position: relative;
	padding-bottom: 76.1904%;
	overflow: hidden;
}



// .locator-input {
// 	display: flex;
// 	align-items: stretch;
// 	align-content: center;
// 	padding: 5px;
// 	background: #ededed;
// }

// .locator-input__values {
// 	display: flex;
// 	flex-direction: row;
// 	align-items: stretch;
// 	align-content: center;
// 	flex: 3 1 auto;
// }

// @media only screen and (min-width: 800px) {
// 	.locator-input {
// 		margin-bottom: 5px;
// 		border-radius: 3px;
// 	}
// }

// .locator-input__location, .locator-input__range, .locator-input__submit {
// 	padding: 5px;
// 	background: #fff;
// 	border: 1px solid #ddd;
// }

// .locator-input__location {
// 	flex: 3 1 auto;
// 	padding: 0 !important;
// }

// .locator-input__range {
// 	margin: 0 5px;
// 	padding: 0 !important;
// }

.locator-input__submit {
	position: relative;
	align-self: flex-end;
}

.locator-search__submit {
	display: block;
	width: 32px;
	height: 28px;
	margin: 0;
	padding: 0;
	font-size: 0;
	line-height: 16px;
	background-image: url('/images/search.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 16px 16px;
	border: none;
	text-indent: 100%;
	overflow: hidden;
	white-space: nowrap;
}

// .locator-search__range {
// 	padding: 5px;
// 	font-size: 12px;
// 	color: #6d6d6d;
// 	border: none;
// }

// .locator-search__text {
// 	display: block;
// 	width: 100%;
// 	padding: 5px;
// 	font-size: 12px;
// 	line-height: 1.4;
// 	border: none;
// }

@media only screen and (min-width: 800px) {
	.locator__inner {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 325px;
		z-index: 300;
	}

	.locator__inner--left {
		right: auto;
		left: 10px;
	}
}

// --- locator form
.locator-input {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	column-gap: 5px;
	margin-bottom: 5px;
	padding: 5px;
	background-color: #ededed;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.locator-input__label {
	display: block;
	margin-bottom: 2px;
	padding: 0 3px;
}

.locator-input__location {
	flex: 1;
}

.locator-search__text, .locator-search__range, .locator-search__submit {
	height: 28px;
	padding: 5px;
	background-color: #fff;
	border: 1px solid #ddd;
}

.locator-search__text {
	width: 100%;
	margin: 0;
}

// .locator-search__range {
// 	padding: 4px 5px;
// }
.locator-search__range {
	max-width: 100px;
	padding: 5px 20px 5px 5px;
	background-color: #fff;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+);
	background-position: 95% 50%;
	background-repeat: no-repeat;
	border: 1px solid #ddd;
	border-radius: 3px;
	appearance: none;
}


.locator-results {}

.locator-result {
	padding-left: 10px;
	font-size: 0.9em;
}

.locator-result__icon {
	float: left;
	overflow: hidden;
}

.locator-result__info {
	padding: 1px 10px;
	overflow: hidden;
}

.locator-result__section {
	padding: 3px 0;
	line-height: 1.5;
	overflow: hidden;
}

.icon--map-home {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('/images/map/home-map-marker.png') no-repeat 0 0;
	background-size: 18px 18px;
	opacity: 0.65;
}

.icon--phone {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('/images/map/phone.png') no-repeat 0 0;
	background-size: 18px 18px;
	opacity: 0.65;
}

.icon--clock {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('/images/map/clock.png') no-repeat 0 0;
	background-size: 18px 18px;
	opacity: 0.65;
}

.icon--pin {
	position: absolute;
	top: 5px;
	left: 10px;
	width: 22px;
	height: 40px;
	padding: 3px 1px 0 0;
	font-size: 1.15em;
	text-align: center;
	background: url('/images/map/pin.png') no-repeat 0 0;
}

.icon--car {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('/images/map/car.png') no-repeat 0 0;
	background-size: 18px 18px;
	opacity: 0.65;
}

.icon--link {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('/images/map/link.png') no-repeat 0 0;
	background-size: 18px 18px;
	opacity: 0.65;
}

.locator-results__list {
	margin: 0;
	padding: 10px 0;
	list-style: none;
}

.locator-results__item {
	position: relative;
	padding: 10px 10px 10px 30px;
}

.locator-result__name {
	margin: 0 0 5px;
	padding: 0;
	font-size: 1.15em;
}

@media only screen and (min-width: 800px) {
	.locator-results {
		max-height: 31em;
		border: 1px solid #ddd;
		background: #fff;
		border-radius: 3px;
		overflow-y: auto;
	}
}


.locator__count {
	display: flex;
	align-items: center;
	padding: 5px 10px;
	font-size: 14px;
	background: #fff;
	border: 1px solid #ddd;
	border-top: none;
	overflow: hidden;
}

.locator__count > p {
	margin: 0;
	padding: 0;
}

@media only screen and (min-width: 800px) {
	.locator__count {
		margin: 0 0 5px;
		padding: 10px;
		border-radius: 3px;
	}
}

.locator__count-pin {
	float: left;
	display: block;
	width: 14px;
	height: auto;
	margin-right: 15px;
}

.locator-results__list--highlighted .locator-results__item {
	opacity: 0.4;
}

.locator-results__list--highlighted .locator-results__item--highlight {
	opacity: 1;
}

.gm-style-iw .icon--pin {
	display: none;
}

// --- filters
.locator-filters {
	padding: 5px;
	margin-bottom: 5px;
	background: #fff;
	border: 1px solid #ddd;
}

.locator-filters__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.locator-filters__item {
	padding-bottom: 10px;
}

.locator-filters__toggle {
	display: block;
	padding: none;
	line-height: 1;
	background: none;
	border: none;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.3s;
}

.locator-filters__toggle::before {
	content: '';
	display: inline-block;
	// width: 22px;
	// height: 40px;
	width: 11px;
	height: 20px;
	margin-right: 5px;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 11px 20px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.locator-filters__toggle--inactive {
	opacity: 0.5;
}
