@charset "UTF-8";
/*
 * 1.) Settings
 * #colors
 *
 * 2.) Utilities
 * #grid
 * #custom-select
 *
 * 3.) Generic
 * #box-sizing [TODO: Replace with Normalize.css]
 * #html-5 [TODO: Replace with Normalize.css]
 *
 * 4.) Base
 * #html-body
 * #headings [TODO: Replace with classes]
 * #default-text
 * #default-images
 * #default-form [TODO: Replace with classes]
 * #form-utilities [TODO: Refactor/look at usage]
 * #text-utilities
 * #layout-utilities
 * #responsive-video
 * #list-utilities
 * #element-loading [TODO: Refactor, move to objects]
 *
 * 5.) Objects
 * #wrappers
 * #buttons [TODO: Refactor, split into multiple options]
 * #icons [TODO: Make more consistent]
 *
 * 6.) Components
 * #header-top
 * #header-banner
 * #header-search
 * #site-logo
 * #site-menu
 * #site-footer
 * #social-links
 * #breadcrumbs
 * #product-filters
 * #product-bar [TODO: Refactor]
 * #pagination
 * #page-size
 * #page-sort
 * #product-list-page [TODO: Refactor]
 * #product-list-grid [TODO: Refactor]
 * #product-list-list [TODO: Refactor]
 * #prod-options-related [TODO: Refactor (this should be a product list)]
 * #grid-list-view [TODO: Refactor]
 * #overlay [TODO: Refactor. Combine with spinners, etc]
 * #modal
 * #results-spinner
 * #product-info
 * #product-pricing
 * #product-details
 * #product-sharing
 * #product-images
 * #external-links
 * #tooltip
 * #quick-view-modal
 * #no-stock
 * #added-message
 * #site-locator [TODO: Should probably be its own file]
 * #education [TODO: Separate file?]
 * #education-all [TODO: Separate file?]
 * #education-table
 * #education-list
 * #login-register
 * #register-guest
 * #contact-us
 * #tabs
 * #about-us
 * #home-page-catalogs
 * #quick-order
 * #checkout [TODO: Separate file?]
 * #order-history [TODO: Separate file for account stuff?]
 * #order-details [TODO: Separate file for account stuff?]
 * #account-nav [TOOD: Separate file for account stuff?]
 * #account-information
 * #shopping-cart [TODO: Refactor]
 * #shopping-list
 * #home-page
 * #home-articles
 * #home-facebook
 * #fade-rotator
 * #header-message
 * #article-posts [TODO: Separate file?]
 * #tag-cloud
 * #coupon-code
 * #gallery
 * #saved-cards
 * #popover
 * #sample-program [TODO: Cart file?]
 * #new-cart [TODO: Work into actual cart]
 * #home-categories
 * #pencil-banner
 * #explore-deals
 * #dev-message
 * #multi-select
 * #login-modal
 * #toggler
 * #or
 * #autocomplete
 * #instagram-widget
 * #google-translate
 *
 * 7.) Overrides
 * #sharing-overrides
 * #layout-overrides
 * #text-overrides
 * #clearfix
 * #temporary-overrides [TODO: Un-temporarize these]
 *
 */
@keyframes circle {
  0%, 100% {
    box-shadow: 0em -2em 0em 0em white, 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2), 2em 0em 0em 0em rgba(255, 255, 255, 0.2), 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), 0em 2em 0em 0em rgba(255, 255, 255, 0.2), -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.4), -2em 0em 0em 0em rgba(255, 255, 255, 0.6), -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.8);
  }
  12.5% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.8), 1.4em -1.4em 0em 0em white, 2em 0em 0em 0em rgba(255, 255, 255, 0.2), 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), 0em 2em 0em 0em rgba(255, 255, 255, 0.2), -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), -2em 0em 0em 0em rgba(255, 255, 255, 0.4), -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.6);
  }
  25% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.6), 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.8), 2em 0em 0em 0em white, 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), 0em 2em 0em 0em rgba(255, 255, 255, 0.2), -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), -2em 0em 0em 0em rgba(255, 255, 255, 0.2), -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.4);
  }
  37.5% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.4), 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.6), 2em 0em 0em 0em rgba(255, 255, 255, 0.8), 1.4em 1.4em 0em 0em white, 0em 2em 0em 0em rgba(255, 255, 255, 0.2), -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), -2em 0em 0em 0em rgba(255, 255, 255, 0.2), -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.2), 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.4), 2em 0em 0em 0em rgba(255, 255, 255, 0.6), 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.8), 0em 2em 0em 0em white, -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), -2em 0em 0em 0em rgba(255, 255, 255, 0.2), -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.2), 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2), 2em 0em 0em 0em rgba(255, 255, 255, 0.4), 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.6), 0em 2em 0em 0em rgba(255, 255, 255, 0.8), -1.4em 1.4em 0em 0em white, -2em 0em 0em 0em rgba(255, 255, 255, 0.2), -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.2), 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2), 2em 0em 0em 0em rgba(255, 255, 255, 0.2), 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.4), 0em 2em 0em 0em rgba(255, 255, 255, 0.6), -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.8), -2em 0em 0em 0em white, -1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2em 0em 0em rgba(255, 255, 255, 0.2), 1.4em -1.4em 0em 0em rgba(255, 255, 255, 0.2), 2em 0em 0em 0em rgba(255, 255, 255, 0.2), 1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.2), 0em 2em 0em 0em rgba(255, 255, 255, 0.4), -1.4em 1.4em 0em 0em rgba(255, 255, 255, 0.6), -2em 0em 0em 0em rgba(255, 255, 255, 0.8), -1.4em -1.4em 0em 0em white;
  }
}

.row, .row-padded, .row-nopad {
  position: relative;
  clear: both;
  margin: 0;
  *zoom: 1;
}

.row::before, .row::after, .row-padded::before, .row-padded::after, .row-nopad::before, .row-nopad::after {
  content: '';
  display: table;
}

.row::after, .row-padded::after, .row-nopad::after {
  clear: both;
}

.row .row {
  margin-right: -10px;
  margin-left: -10px;
  padding-left: 0;
  padding-right: 0;
}

.row-padded {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.g-1, .md-1, .sm-1, .g-2, .md-2, .sm-2, .g-3, .md-3, .sm-3, .g-4, .md-4, .sm-4, .g-5, .md-5, .sm-5, .g-6, .md-6, .sm-6, .g-7, .md-7, .sm-7, .g-8, .md-8, .sm-8, .g-9, .md-9, .sm-9, .g-10, .md-10, .sm-10, .g-11, .md-11, .sm-11, .g-12, .md-12, .sm-12, .g-quarter, .md-quarter, .sm-quarter, .g-third, .md-third, .sm-third, .g-half, .md-half, .sm-half, .g-full, .md-full, .sm-full {
  position: relative;
  float: left;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-nopad > .g-1, .row-nopad > .md-1, .row-nopad > .sm-1, .row-nopad > .g-2, .row-nopad > .md-2, .row-nopad > .sm-2, .row-nopad > .g-3, .row-nopad > .md-3, .row-nopad > .sm-3, .row-nopad > .g-4, .row-nopad > .md-4, .row-nopad > .sm-4, .row-nopad > .g-5, .row-nopad > .md-5, .row-nopad > .sm-5, .row-nopad > .g-6, .row-nopad > .md-6, .row-nopad > .sm-6, .row-nopad > .g-7, .row-nopad > .md-7, .row-nopad > .sm-7, .row-nopad > .g-8, .row-nopad > .md-8, .row-nopad > .sm-8, .row-nopad > .g-9, .row-nopad > .md-9, .row-nopad > .sm-9, .row-nopad > .g-10, .row-nopad > .md-10, .row-nopad > .sm-10, .row-nopad > .g-11, .row-nopad > .md-11, .row-nopad > .sm-11, .row-nopad > .g-12, .row-nopad > .md-12, .row-nopad > .sm-12 {
  padding-left: 0;
  padding-right: 0;
}

.g-right {
  float: right;
}

.g-1 {
  width: 8.33333%;
}

.push-1 {
  left: 8.33333%;
}

.pull-1 {
  right: 8.33333%;
}

.g-2 {
  width: 16.66667%;
}

.push-2 {
  left: 16.66667%;
}

.pull-2 {
  right: 16.66667%;
}

.g-3 {
  width: 25%;
}

.push-3 {
  left: 25%;
}

.pull-3 {
  right: 25%;
}

.g-4 {
  width: 33.33333%;
}

.push-4 {
  left: 33.33333%;
}

.pull-4 {
  right: 33.33333%;
}

.g-5 {
  width: 41.66667%;
}

.push-5 {
  left: 41.66667%;
}

.pull-5 {
  right: 41.66667%;
}

.g-6 {
  width: 50%;
}

.push-6 {
  left: 50%;
}

.pull-6 {
  right: 50%;
}

.g-7 {
  width: 58.33333%;
}

.push-7 {
  left: 58.33333%;
}

.pull-7 {
  right: 58.33333%;
}

.g-8 {
  width: 66.66667%;
}

.push-8 {
  left: 66.66667%;
}

.pull-8 {
  right: 66.66667%;
}

.g-9 {
  width: 75%;
}

.push-9 {
  left: 75%;
}

.pull-9 {
  right: 75%;
}

.g-10 {
  width: 83.33333%;
}

.push-10 {
  left: 83.33333%;
}

.pull-10 {
  right: 83.33333%;
}

.g-11 {
  width: 91.66667%;
}

.push-11 {
  left: 91.66667%;
}

.pull-11 {
  right: 91.66667%;
}

.g-12 {
  width: 100%;
}

.push-12 {
  left: 100%;
}

.pull-12 {
  right: 100%;
}

.g-hide {
  display: none;
}

.g-show {
  display: block;
}

.g-quarter {
  width: 25%;
}

.g-third {
  width: 33.33333%;
}

.g-half {
  width: 50%;
}

.g-full {
  width: 100%;
}

.g-clear {
  clear: left;
}

@media only screen and (max-width: 680px) {
  .md-1 {
    width: 8.33333%;
  }
  .push-1 {
    left: 0;
  }
  .pull-1 {
    right: 0;
  }
  .md-2 {
    width: 16.66667%;
  }
  .push-2 {
    left: 0;
  }
  .pull-2 {
    right: 0;
  }
  .md-3 {
    width: 25%;
  }
  .push-3 {
    left: 0;
  }
  .pull-3 {
    right: 0;
  }
  .md-4 {
    width: 33.33333%;
  }
  .push-4 {
    left: 0;
  }
  .pull-4 {
    right: 0;
  }
  .md-5 {
    width: 41.66667%;
  }
  .push-5 {
    left: 0;
  }
  .pull-5 {
    right: 0;
  }
  .md-6 {
    width: 50%;
  }
  .push-6 {
    left: 0;
  }
  .pull-6 {
    right: 0;
  }
  .md-7 {
    width: 58.33333%;
  }
  .push-7 {
    left: 0;
  }
  .pull-7 {
    right: 0;
  }
  .md-8 {
    width: 66.66667%;
  }
  .push-8 {
    left: 0;
  }
  .pull-8 {
    right: 0;
  }
  .md-9 {
    width: 75%;
  }
  .push-9 {
    left: 0;
  }
  .pull-9 {
    right: 0;
  }
  .md-10 {
    width: 83.33333%;
  }
  .push-10 {
    left: 0;
  }
  .pull-10 {
    right: 0;
  }
  .md-11 {
    width: 91.66667%;
  }
  .push-11 {
    left: 0;
  }
  .pull-11 {
    right: 0;
  }
  .md-12 {
    width: 100%;
  }
  .push-12 {
    left: 0;
  }
  .pull-12 {
    right: 0;
  }
  .md-quarter {
    width: 25%;
  }
  .md-third {
    width: 33.33333%;
  }
  .md-half {
    width: 50%;
  }
  .md-full {
    width: 100%;
  }
  .md-hide {
    display: none;
  }
  .md-show {
    display: block;
  }
  .row .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .g-clear {
    clear: none;
  }
  .md-clear {
    clear: both;
  }
}

@media only screen and (max-width: 480px) {
  .sm-1 {
    width: 8.33333%;
  }
  .sm-2 {
    width: 16.66667%;
  }
  .sm-3 {
    width: 25%;
  }
  .sm-4 {
    width: 33.33333%;
  }
  .sm-5 {
    width: 41.66667%;
  }
  .sm-6 {
    width: 50%;
  }
  .sm-7 {
    width: 58.33333%;
  }
  .sm-8 {
    width: 66.66667%;
  }
  .sm-9 {
    width: 75%;
  }
  .sm-10 {
    width: 83.33333%;
  }
  .sm-11 {
    width: 91.66667%;
  }
  .sm-12 {
    width: 100%;
  }
  .sm-quarter {
    width: 25%;
  }
  .sm-third {
    width: 33.33333%;
  }
  .sm-half {
    width: 50%;
  }
  .sm-full {
    width: 100%;
  }
  .sm-hide {
    display: none;
  }
  .sm-show {
    display: block;
  }
  .g-clear, .md-clear {
    clear: none;
  }
  .sm-clear {
    clear: both;
  }
}

.custom-select {
  display: block;
  position: relative;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.custom-select select {
  width: 100%;
  margin: 0;
  padding: 0.6em 0.8em 0.5em 0.8em;
  font-size: inherit;
  box-sizing: border-box;
}

.custom-select::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0.5em;
  z-index: 2;
  display: none;
  pointer-events: none;
}

.custom-select::after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid silver;
  margin-top: -3px;
}

@supports (-webkit-appearance: none) or (appearance: none) {
  .custom-select::after {
    display: block;
  }
  .custom-select select {
    padding-right: 2em;
    background: none;
    border: 1px solid transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select select:focus {
    border-color: #ddd;
  }
}

@supports (-moz-appearance: none) and (mask-type: alpha) {
  .custom-select::after {
    display: block;
  }
  .custom-select select {
    padding-right: 2em;
    background: none;
    border: 1px solid transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select select:focus {
    border-color: #ddd;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  overflow: hidden;
}

*, *::after, *::before {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #4c4c4c;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 0.9em;
  background-color: white;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 2.027em;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
}

h2 {
  margin: 0 0 0.85em;
  padding: 0;
  font-size: 1.802em;
  line-height: 1;
}

h3 {
  margin: 0 0 1em;
  padding: 0;
  font-size: 1.602em;
  line-height: 1.05;
}

h4 {
  margin: 0 0 1.25em;
  padding: 0;
  font-size: 1.424em;
  line-height: 1.15;
}

h5 {
  margin: 0 0 1.35em;
  padding: 0;
  font-size: 1.266em;
  line-height: 1.25;
}

h6 {
  margin: 0 0 1.4em;
  padding: 0;
  font-size: 1.125em;
  line-height: 1.4;
}

a {
  color: #005ca0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

small {
  font-size: 1em;
}

img {
  border: none;
}

textarea {
  height: 6em;
  font-family: inherit;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input, .input--xs, .input--sm, .input--md, .input--lg, .input--full, .input--qty, .input--price {
  max-width: 100%;
  padding: 3px 6px;
  border: 1px solid silver;
  border-radius: 1px;
}

.input--qty {
  max-width: 3em;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .input--qty {
    font-size: 16px;
    line-height: 1.2em;
  }
}

.input--price {
  max-width: 5em;
  text-align: right;
}

.input--xs {
  width: 50px;
}

.input--sm {
  width: 175px;
}

.input--md {
  width: 350px;
}

.input--lg {
  width: 500px;
}

.input--full {
  width: 100%;
}

.label-block {
  display: block;
  margin-bottom: 2px;
}

.placeholder {
  color: #aaaaaa;
}

.readonly {
  margin-bottom: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  border: none;
}

.inactive {
  cursor: default;
  pointer-events: none;
}

.callTopic {
  cursor: pointer;
}

.phone-nr {
  white-space: nowrap;
}

.error {
  color: #d32121;
}

.lbl--error {
  color: #d32121;
  font-weight: bold;
}

.lbl--success {
  color: limegreen;
  font-weight: bold;
}

.txt--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.basic-line {
  height: 1px;
  background: silver;
  border: none;
}

.evenrow {
  background-color: #eeeeee;
}

.oddrow {
  background-color: white;
}

.img--right {
  float: right;
  max-width: 50%;
  margin: 0 0 15px 15px;
}

.img--left {
  float: left;
  max-width: 50%;
  margin: 0 15px 15px 0;
}

.basic-form label {
  display: block;
  margin: 0 0 2px;
}

.basic-form > li {
  margin: 0 0 5px;
}

.basic-form .basic-form--city {
  float: left;
  width: 50%;
  padding-right: 5px;
}

.basic-form .basic-form--zip {
  float: left;
  width: 50%;
  padding-left: 5px;
}

.resp-img {
  display: block;
  height: auto;
  max-width: 100%;
}

.responsive-video {
  position: relative;
  height: 0;
  max-width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}

.responsive-video iframe, .responsive-video object, .responsive-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video__link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
}

.list--plain {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list--inline {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list--inline > li {
  display: inline-block;
  vertical-align: middle;
}

.faux-table {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

.faux-table > li, .faux-table__row {
  display: table-row;
}

.faux-col, .faux-table__col {
  display: table-cell;
  padding: 5px 10px;
}

.faux-col:first-of-type, .faux-table__col:first-of-type {
  padding-right: 0;
  text-align: right;
  white-space: nowrap;
}

.faux-col .readonly, .faux-table__col .readonly {
  width: 100%;
}

.list--left {
  margin: 0;
  padding: 0 0 0 1.5em;
  text-align: left;
}

.loading {
  position: relative;
  overflow: hidden;
}

.loading::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: url("/images/white-60.png") repeat;
  background: rgba(255, 255, 255, 0.6);
}

.loading::after {
  content: "Loading";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  margin: auto;
  width: 16px;
  height: 16px;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  background: url("/images/spinner-small.gif") no-repeat 0 0;
}

.title-wrap {
  padding-top: 2em;
}

@media only screen and (max-width: 680px) {
  .title-wrap {
    padding-top: 0;
  }
}

.main-content {
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 400px;
  background-color: white;
}

.header-wrap {
  padding: 10px 10px 5px;
}

.button {
  display: inline-block;
  padding: 6px 12px;
  color: #c0c0c0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: #333;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  appearance: none;
}

.button:hover {
  color: #fff;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
}

.button--small {
  padding: 5px 10px;
  font-size: 12px;
  text-transform: none;
}

.button--large {
  padding: 12px 24px;
  font-size: 16px;
}

.button--block {
  display: block;
  width: 100%;
  margin: 0 0 5px;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
  border-color: #c8c8c8;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--text {
  color: #333;
  text-transform: none;
  background: none;
  border: none;
}

.button--text:hover {
  color: #333;
  text-decoration: underline;
}

.button--quickview {
  padding: 6px 12px;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  line-height: 1;
  text-transform: none;
  background: #333;
  border-radius: 3px;
}

.button--search {
  display: block;
  width: 14px;
  height: 14px;
  background: url("/images/search.png") no-repeat 0 0;
  background-size: 14px 14px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.button--download::before {
  content: '\2193\00a0\00a0';
  color: inherit;
  font-weight: bold;
}

.link--pdf {
  display: inline-block;
  position: relative;
  padding-left: 37px;
}

.link--pdf::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  background: url("/images/pdf_download_32x32.png") no-repeat 0 0;
  white-space: nowrap;
  overflow: hidden;
}

.button--hidden {
  display: none;
}

.button--disabled {
  opacity: 0.4;
  cursor: default;
}

.button--pending {
  position: relative;
  pointer-events: none;
}

.button--pending::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: block;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 3px;
  border-radius: 100%;
  animation: circle 1s infinite ease;
}

.button--pending::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.button--small.button--pending::before {
  font-size: 2px;
}

.button--large.button--pending::before {
  font-size: 5px;
}

.no-cssanimations .button--pending {
  opacity: 0.4;
}

.no-cssanimations .button--pending::before, .no-cssanimations .button--pending::after {
  display: none;
}

@media only screen and (max-width: 480px) {
  .button--notify {
    padding: 5px 0;
  }
}

.burger {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0;
  vertical-align: text-top;
}

.burger::before, .burger::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 3px;
  background: white;
}

.burger::before {
  top: auto;
  bottom: 0;
}

.burger__middle {
  display: block;
  position: absolute;
  height: 3px;
  top: 6px;
  right: 0;
  left: 0;
  background: white;
}

.offscreen-active .burger {
  vertical-align: baseline;
}

.offscreen-active .burger__middle {
  opacity: 0;
}

.offscreen-active .burger::before, .offscreen-active .burger::after {
  top: 50%;
  bottom: auto;
}

.offscreen-active .burger::before {
  transform: rotate(45deg);
}

.offscreen-active .burger::after {
  transform: rotate(-45deg);
}

.i--twitter, .i--facebook, .i--linkedin, .i--pinterest, .i--youtube, .i--wordpress, .i--feed, .i--instagram, .i--bangstyle, .i--email, .i--tiktok {
  display: block;
  width: 25px;
  height: 25px;
  background: #444 url("/images/socialicons.png") no-repeat 0 0;
  border-radius: 100%;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.i--email {
  background-image: url("/images/email-icon.png");
}

.i--twitter {
  background-position: 2px 0;
}

.i--facebook {
  background-position: -24px 0;
}

.i--linkedin {
  background-position: -542px 0;
}

.i--pinterest {
  background-position: -256px 0;
}

.i--youtube {
  background-position: -152px 0;
}

.i--wordpress {
  background-position: -595px 0;
}

.i--feed {
  background-position: -646px 0;
}

.i--instagram {
  background-position: -696px 1px;
}

.i--bangstyle {
  background-position: -724px 1px;
}

.i--tiktok {
  background-position: -748px 0px;
}

.i--twitter-hover {
  background-color: #3dc7f4;
}

.i--facebook-hover {
  background-color: #3b5998;
}

.i--linkedin-hover {
  background-color: #00659b;
}

.i--pinterest-hover {
  background-color: #dc3e47;
}

.i--youtube-hover {
  background-color: #e40202;
}

.i--wordpress-hover {
  background-color: #247ca4;
}

.i--feed-hover {
  background-color: #e08e00;
}

.i--instagram-hover {
  background-color: #773eb1;
}

.i--bangstyle-hover {
  background-color: #9497ca;
}

.i--tiktok-hover {
  background-color: #00f2ea;
}

.i--email {
  background-color: #333333;
}

.i--spinner {
  width: 64px;
  height: 64px;
  margin: 0 auto;
  background: url("/images/spinner.gif");
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.icon--fb::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 0.25em;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  background: #3b5998 url("/images/socialicons.png") no-repeat -25px -2px;
  vertical-align: bottom;
}

.home-social__item .i--twitter, .home-social__item .i--facebook, .home-social__item .i--linkedin, .home-social__item .i--pinterest, .home-social__item .i--youtube, .home-social__item .i--instagram, .home-social__item .i--email, .home-social__item .i--tiktok {
  width: 54px;
  height: 54px;
  background: url("/images/socialicons-home-black.png") no-repeat 0 0;
}

.home-social__item .i--instagram {
  background-position: -145px 0;
}

.home-social__item .i--facebook {
  background-position: -72px 0;
}

/**
 * 5 - Components
 */
.header-top {
  position: fixed;
  z-index: 9000;
  width: 100%;
  background-color: #ddd;
  transition: background-color 1s, color 1s;
}

.header-top--added {
  background-color: #338326;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.header-top__Impersonate, .header-top__TestMode {
  display: inline-block;
  margin-bottom: 4px;
  padding: 1px 4px;
  color: white;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: none;
  background-color: #ff0000;
}

.header-top__account {
  padding: 5px;
}

.header-top__account, .header-top__inner {
  max-width: 1000px;
  margin: 0 auto;
}

.header-top__links {
  float: right;
}

.header-top__link {
  float: left;
  margin: 0 0 0 5px;
  padding: 0 0 0 5px;
  border-left: 1px solid #7e7e7e;
}

.header-top__link:first-child {
  margin: 0;
  padding: 0;
  border-left: none;
}

.header-top__assistance {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.header-top__link > a {
  color: #333333;
}

.header-top a {
  color: inherit;
}

.header-top__countdown {
  clear: both;
  padding: 5px 0;
  font-size: 12px;
  font-style: italic;
  text-align: right;
}

@media only screen and (max-width: 680px) {
  .header-top {
    position: static;
  }
  .header-top__links {
    float: none;
    padding: 0 0 5px;
  }
  .header-top__link:nth-child(2) {
    margin: 0;
    padding: 0;
    border-left: none;
  }
  .header-top__link--greeting {
    display: none;
  }
  .header-top__link--login {
    text-align: left;
  }
  .header-top__countdown {
    text-align: left;
  }
}

.utility-bar {
  background-color: #000;
}

.utility-bar__inner {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

.utility-bar__inner .header-search {
  width: 150px;
}

.locator-feature {
  padding: 10px 0;
}

.account-header {
  position: relative;
  padding: 10px 5px;
  color: #fff;
  background-color: #000;
}

.account-header__header-i-guess {
  display: grid;
  grid-template-columns: 1fr auto auto;
  text-align: right;
}

.account-header__title {
  grid-row: 1;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
}

.account-header__account-name {
  display: block;
}

.account-header__icon {
  display: none;
  grid-row: 1 / span 2;
  height: 40px;
  width: 40px;
  margin-left: 15px;
  overflow: hidden;
}

.account-header__icon .icon-person svg {
  width: 40px;
  height: 40px;
}

.account-header__icon .icon-person .icon-circle {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
}

.account-header__icon .icon-person .icon-body, .account-header__icon .icon-person .icon-head {
  fill: #fff;
  stroke: none;
}

@media only screen and (min-width: 820px) {
  .account-header__icon {
    display: block;
  }
}

.account-header__icon-link {
  display: block;
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.account-header__toggle {
  display: none;
  grid-row: 1 / span 2;
  grid-column: 3;
  align-self: center;
  margin: 0;
  padding: 5px 10px;
  line-height: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.account-header__toggle svg {
  fill: #fff;
}

@media only screen and (min-width: 820px) {
  .account-header__toggle {
    display: block;
  }
}

.header-cart__link {
  display: inline-block;
  position: relative;
  top: 3px;
}

.header-cart__icon {
  display: block;
  width: 50px;
}

.header-cart__icon svg {
  width: 50px;
  height: 50px;
  fill: #fff;
  transition: fill 0.3s;
}

.header-cart--updated .header-cart__icon svg {
  fill: green;
  transition: fill 0.3s;
}

.header-cart__value {
  position: absolute;
  top: 20%;
  right: 7%;
  left: 29%;
  display: block;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.login-links {
  padding: 10px 0;
}

@media only screen and (max-width: 620px) {
  .header-cart__value {
    top: 17%;
  }
}

@media only screen and (min-width: 820px) {
  .top-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;
  }
}

.utility-bar__inner {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.header-search {
  align-self: center;
}

.locator-feature {
  grid-column: 1;
  align-self: end;
}

.account-header {
  grid-column: 2;
}

.header-cart {
  grid-column: 3;
  align-self: center;
}

.login-links {
  grid-column: 2 / span 2;
  align-self: center;
  justify-self: end;
}

.is--logged-in .login-links {
  align-self: end;
}

.account-header__nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  width: 260px;
  margin-left: 260px;
  padding-top: 2em;
  background-color: #000;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(260px);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.account-header__nav.is--active {
  transform: translateX(0);
}

.account-header__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.account-header__item {
  padding: 10px 10px 10px 0;
  margin: 0 10px;
  border-top-color: #fff;
  border-top-style: solid;
  border-top-width: 1px;
}

.account-header__item:first-child {
  border-top-color: transparent;
}

.account-header__link {
  color: inherit;
  text-transform: uppercase;
}

.account-header__close {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 0.25em 0.5em;
  color: #fff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1;
  background: none;
  border: none;
}

.account-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;
  z-index: 8000;
  background: black;
  opacity: 0;
  transition: bottom 0.1s 0.3s, opacity 0.3s ease-in;
}

.account-overlay.is--active {
  bottom: 0;
  opacity: 0.6;
  transition: bottom 0.1s, opacity 0.3s 0.1s ease-in;
}

@media only screen and (min-width: 820px) {
  .account-header__close {
    display: none;
  }
  .account-header__nav {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 8100;
    bottom: auto;
    padding-top: 0;
    transform: none;
  }
  .account-header__nav.is--active {
    display: block;
    transform: none;
  }
  .account-overlay {
    background-color: rgba(255, 255, 255, 0);
  }
}

.myaccount-nav {
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.myaccount-nav__link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 25px 10px 30px;
  border-radius: 25px;
  margin: 5px 10px;
  transition: 0.3s all;
  background-color: #bfbfbf;
}

.myaccount-nav__link--selected {
  background-color: #737373;
}

.myaccount-nav__link:hover {
  text-decoration: none;
  background-color: #737373;
}

.myaccount-nav__icon {
  display: none;
}

.myaccount-nav__text {
  text-align: center;
  width: 100%;
}

.myaccount-nav__name {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.9em;
}

.myaccount-nav__description {
  margin: 5px 0 0;
  padding: 0;
}

@media only screen and (min-width: 620.02px) {
  .myaccount-nav {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 820px) {
  .myaccount-nav {
    grid-template-columns: repeat(5, 1fr);
    margin: 20px 0;
  }
  .myaccount-nav__link {
    margin: 0px 10px;
  }
  .myaccount-nav__icon {
    display: block;
    text-align: center;
  }
  .myaccount-nav__icon img {
    width: 24px;
  }
  .myaccount-nav__icon-qo img {
    width: 30px;
  }
  .myaccount-nav__text {
    flex: 1;
    align-self: center;
    margin-left: 10px;
    text-align: left;
  }
}

.header-banner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;
}

.header-banner__buttons {
  margin: 5px -5px 0;
  overflow: hidden;
}

.header-banner__button {
  float: left;
  width: 50%;
  padding: 0 5px;
}

.header-banner .social {
  float: right;
}

@media only screen and (min-width: 540px) {
  .header-banner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .header-banner__section--left {
    flex: 1 1 auto;
    align-self: center;
    min-height: 1px;
  }
  .header-banner__section--right {
    flex: 0 0 270px;
    align-self: flex-end;
    margin-left: auto;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 1000px) {
  .header-banner {
    padding: 10px 0;
  }
}

.search {
  clear: both;
  display: flex;
  background: white;
  border: 1px solid silver;
  border-radius: 3px 0 0 3px;
}

.search__options {
  flex: 0 0 95px;
  font-size: 14px;
  background: #eeeeee;
  border: none;
  border-radius: 0;
}

.search__text {
  position: relative;
  display: flex;
  flex: 9 0;
  border-left: 1px solid silver;
}

.search__field {
  display: block;
  width: 100%;
  padding: 5px;
  border: none;
}

.search__submit {
  flex: 0 0 14px;
  align-self: center;
  margin: 0 5px;
  border: none;
  cursor: pointer;
}

.search__options > select {
  padding: 0.2em 2em 0.3em 0.3em;
}

@media only screen and (max-width: 480px) {
  .search__text input {
    font-size: 16px;
  }
}

.site-logo__link {
  display: inline-block;
}

.site-logo__img {
  display: block;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 540px) {
  .site-logo {
    padding-right: 15px;
  }
}

.header-banner__section--menu {
  display: none;
}

.site-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9000;
  width: 260px;
  padding-top: 2em;
  margin-left: -260px;
  background: #efefef;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.site-nav.is--active {
  transform: translateX(260px);
}

.site-nav__list {
  border-top: 1px solid #ddd;
}

.site-nav__close {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 0.25em 0.5em;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1;
  background: none;
  border: none;
}

.site-nav__item {
  border-bottom: 1px solid #ddd;
}

.site-nav__top {
  display: block;
  padding: 15px;
  background: #fafafa;
  color: #555;
  font-size: 1.05em;
  font-weight: bold;
  text-transform: uppercase;
}

.site-nav__top:hover {
  text-decoration: none;
}

.site-nav__top--has-sub::after {
  content: '\00a0\25B8';
}

.site-nav__sub {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  min-height: 1px;
  margin: 2em 0 0 0;
  border-top: 1px solid #ddd;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  transition: left 0.5s;
}

.site-nav__item--sub-active .site-nav__sub {
  left: 0;
  overflow-y: auto;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    position: static;
    width: auto;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
    background-color: #d6d6d6;
    border-top: none;
    border-right: none;
    transition: none;
    overflow: visible;
  }
  .site-nav__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: none;
  }
  .site-nav__item {
    flex: 0 0 auto;
    border-bottom: none;
  }
  .site-nav__sub {
    display: none;
  }
  .site-nav__top {
    padding: 10px 20px;
    background-color: transparent;
  }
  .site-nav__top--has-sub::after {
    content: '';
  }
  .site-nav__top--inactive {
    cursor: default;
    pointer-events: none;
  }
  .site-nav__close {
    display: none;
  }
  .site-nav__item:hover .site-nav__top {
    background-color: #f4f4f4;
  }
  .site-nav__item:hover .site-nav__sub {
    display: block;
  }
  .site-nav.is--active {
    transform: none;
  }
  .site-nav__item--sub-active .site-nav__sub {
    left: auto;
  }
  .site-nav__inner {
    display: table;
    margin: 0 auto;
  }
}

.sub-nav {
  padding-bottom: 2em;
  background: #efefef;
  border-left: 1px solid #ddd;
}

.sub-nav__col, .sub-nav__col--2, .sub-nav__col--3, .sub-nav__col--4 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sub-nav__back {
  display: block;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.sub-nav__back::before {
  content: '\25C2\00a0';
}

.sub-nav li {
  border-bottom: 1px solid #ddd;
}

.sub-nav a {
  display: block;
  padding: 10px;
  color: inherit;
  background: #fefefe;
  text-decoration: none;
}

.sub-nav .productlinecategory {
  margin: 20px 10px 5px;
  font-weight: bold;
  border-bottom: none;
}

.sub-nav__highlight {
  display: none;
}

@media only screen and (min-width: 820px) {
  .sub-nav {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1000;
    width: auto;
    min-width: 250px;
    margin-top: -1px;
    padding: 10px;
    font-size: 12px;
    background: #f4f4f4;
    border: none;
    border-radius: 0 4px 4px 4px;
  }
  .sub-nav__inner {
    background: #fff;
    padding: 10px;
    border-radius: 3px;
  }
  .sub-nav__col, .sub-nav__col--2, .sub-nav__col--3, .sub-nav__col--4 {
    float: left;
    width: 150px;
  }
  .sub-nav__col--2 {
    width: 200px;
  }
  .sub-nav__col--3 {
    width: 375px;
  }
  .sub-nav__col--4 {
    width: 158px;
    margin-bottom: 15px;
  }
  .sub-nav__col--4:nth-of-type(5n+1) {
    clear: both;
  }
  .sub-nav--big {
    left: 0;
    right: 0;
    max-width: 1000px;
    margin: 0 auto;
  }
  .sub-nav--big .sub-nav__col {
    display: flex;
    flex-flow: column wrap;
    max-height: 500px;
    width: auto;
    float: none;
  }
  .sub-nav--big li {
    float: none;
    width: auto;
    max-width: 20%;
  }
  .sub-nav li {
    border: none;
  }
  .sub-nav a {
    padding: 2px;
    background: none;
  }
  .sub-nav a:hover {
    text-decoration: underline;
  }
  .sub-nav .productlinecategory {
    margin: 0 0 5px;
    text-decoration: underline;
  }
  .sub-nav .sub-nav__back {
    display: none;
  }
  .sub-nav__highlight {
    display: block;
    font-weight: bolder;
    font-size: 11px;
    padding-bottom: 5px;
  }
}

.nav-toggles {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  background-color: silver;
}

.nav-toggle {
  display: block;
  clear: both;
  position: relative;
  z-index: 800;
  width: 100%;
  height: auto;
  padding: 10px 15px;
  color: white;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  text-decoration: none;
  background-color: silver;
  border: none;
  cursor: pointer;
}

.nav-toggle:hover {
  text-decoration: none;
}

.nav-toggle .icon-person {
  display: inline-block;
  fill: white;
  width: 16px;
  vertical-align: text-top;
}

.header-menu-toggle {
  display: none;
}

@media only screen and (min-width: 820px) {
  .nav-toggle, .header-menu-toggle {
    display: none;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8000;
  background: black;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.menu-overlay--active {
  opacity: 0.6;
}

@media only screen and (min-width: 820px) {
  .menu-overlay {
    display: none;
  }
}

.site-footer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px 0;
  background-color: white;
}

.site-footer__panel {
  padding: 0 0 15px;
}

.site-footer__header {
  margin: 0 0 5px;
  padding: 15px 0 5px;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #c8c8c8;
}

.site-footer__item {
  clear: both;
  padding: 5px 0;
}

.site-footer__copyright {
  margin-top: 10px;
  padding: 10px 0;
  color: #7e7e7e;
  font-size: 0.8em;
  text-align: center;
}

.site-footer__copyright .site-footer__site-by {
  text-transform: none;
}

.social__item {
  float: left;
  width: 25px;
  height: 25px;
  margin: 0 7px 7px 0;
  overflow: hidden;
}

.social__link {
  display: block;
  transition: margin-top 0.2s;
}

.social__item:hover .social__link:first-child {
  margin-top: -25px;
}

.breadcrumb {
  padding: 7px 10px;
  font-size: 0.9em;
  text-transform: uppercase;
}

.page--education-default .breadcrumb {
  max-width: 1000px;
  margin: 0 auto;
}

.page--login-default .breadcrumb {
  display: none;
}

.breadcrumb__help {
  float: right;
}

.breadcrumb__links {
  float: left;
}

.breadcrumb__link {
  color: #333333;
  text-decoration: none;
}

.breadcrumb__link:hover {
  text-decoration: none;
}

.breadcrumb__link:hover span {
  text-decoration: underline;
}

.breadcrumb__link::before {
  content: ' / ';
}

.breadcrumb__link:first-child::before {
  content: '';
}

@media only screen and (max-width: 420px) {
  .breadcrumb__help {
    clear: both;
    float: none;
    padding: 5px 0 0;
    font-style: italic;
    text-transform: none;
  }
}

.filters {
  margin: -10px 0 10px;
}

.filters__header {
  margin: 10px 0 0;
  padding: 10px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  background-color: #333333;
  border-radius: 3px;
}

.filters__header a {
  color: white;
}

.filters__toggle {
  float: left;
  display: block;
  font-size: 1em;
  cursor: default;
}

.filters__toggle:hover {
  text-decoration: none;
}

.filters__clear {
  float: right;
}

.filters__section--type {
  padding-bottom: 10px;
}

.filters__link {
  display: block;
  padding: 5px 10px;
  color: #333333;
}

.filters__link:hover {
  background-color: #eeeeee;
}

.filters__link--selected, .filters__link--selected:hover {
  font-weight: bold;
  background-color: #d6d6d6;
  text-decoration: none;
  cursor: default;
}

@media only screen and (max-width: 680px) {
  .filters {
    margin-top: 0;
    border-radius: 3px;
  }
  .filters__section {
    clear: both;
  }
  .filters__section--type {
    padding-bottom: 0;
  }
  .filters__header {
    margin-top: 0;
    border-radius: 0;
  }
  .filters__clear {
    clear: none;
    float: right;
  }
  .filters__toggle {
    cursor: pointer;
  }
  .filters__toggle::before {
    content: '+ ';
    display: inline-block;
    width: 1em;
    text-align: center;
  }
  .filters__list > li {
    float: left;
    width: 50%;
  }
  .filters__list > li:nth-child(odd) {
    clear: left;
  }
  .sidebar--enabled .filters__list {
    display: none;
  }
  .filters__section--expanded .filters__toggle::before {
    content: '- ';
  }
  .filters__section--expanded .filters__list {
    display: block;
  }
}

@media only screen and (max-width: 420px) {
  .filters__list > li {
    float: none;
    width: auto;
  }
}

.product-bar {
  clear: both;
  padding: 5px;
  background-color: #ddd;
}

.product-bar__left {
  float: left;
  width: 30%;
}

.product-bar__center {
  float: left;
  width: 40%;
}

.product-bar__right {
  float: right;
  width: 30%;
}

.product-bar__left--auto {
  float: left;
  width: auto;
  padding: 5px 0;
}

.product-bar__right--auto {
  float: right;
  width: auto;
}

.pagination {
  text-align: center;
  white-space: nowrap;
}

.pagination__controls {
  display: table;
  margin: 0 auto;
}

.pagination__list {
  float: left;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination__item {
  float: left;
  border-left: 1px solid #ccc;
}

.pagination__item:first-child {
  border-left: none;
}

.pagination__link {
  display: block;
  margin: 0;
  padding: 5px;
  width: 2em;
  color: #333333;
  background-color: white;
  text-align: center;
  text-decoration: none;
  transition: background 0.4s;
}

.pagination__link:hover {
  text-decoration: none;
  background-color: #f0f0f0;
}

.pagination__link--inactive, .pagination__link--inactive:hover {
  opacity: 0.5;
  cursor: default;
}

.pagination__link--active, .pagination__link--active:hover {
  color: white;
  background: #333333;
}

.pagination__prev, .pagination__next {
  float: left;
  width: auto;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination__prev {
  margin: 0 5px 0 0;
}

.pagination__prev::before {
  content: '\2190\0020';
}

.pagination__next {
  margin: 0 0 0 5px;
}

.pagination__next::after {
  content: ' \2192';
}

@media only screen and (max-width: 640px) {
  .pagination {
    float: none;
    clear: both;
    width: auto;
    padding-top: 10px;
  }
}

.page-size {
  text-align: right;
}

.page-size__count {
  display: inline-block;
  margin: 0 0 0 5px;
  padding: 5px 0;
  white-space: nowrap;
}

.page-size__controls {
  position: relative;
  display: inline;
}

.page-size__select {
  padding: 4px 5px;
  margin-bottom: 0;
  line-height: 1;
}

.quick-order .page-size__count {
  padding: 3px 0 0;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .page-size {
    width: 50%;
  }
}

.page-sort {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.page-sort__select {
  float: left;
  width: 75%;
  margin-bottom: 0;
  padding: 4px 8px;
  line-height: 1;
}

@media only screen and (max-width: 640px) {
  .page-sort {
    width: 50%;
  }
}

.product-card {
  display: flex;
  flex-direction: column;
}

.product-card__link {
  display: flex;
  flex-direction: column;
  color: inherit;
}

.product-card__link:hover {
  text-decoration: none;
}

.product-card__photo {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 5px;
}

.product-card__name {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.product-card__size, .product-card__sku {
  margin: 0;
  padding: 0;
}

.product-card__sku {
  display: flex;
  flex: 1 0 auto;
  align-items: flex-end;
}

.prod-list__item {
  display: flex;
  flex-direction: column;
}

.product-content {
  max-width: 1000px;
  margin: 0 auto;
}

.product-list {
  margin-top: 5px;
}

.brand-img {
  padding: 0px;
}

.brand-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

.brand-img-banner {
  display: block;
  width: 100%;
  height: auto;
}

.brand-accordion {
  border: 1px solid #333;
  overflow: auto;
}

.brand-accordion__arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 10px;
  transform: rotate(-135deg);
}

.brand-accordion__arrow--down {
  transform: rotate(45deg);
}

.brand-accordion__title {
  background: #333;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.brand-accordion__title h3 {
  margin: 0;
  font-size: 14px;
  line-height: 2.4;
}

.brand-accordion__title--active {
  position: sticky;
  top: 0;
}

.brand-story {
  display: none;
  font-size: 16px;
  padding: 50px 40px;
}

.brand-story li {
  margin-top: 10px;
}

.brand-story h3 {
  margin-bottom: 0;
}

.brand-story .row {
  padding-top: 20px;
}

.brand-story .button {
  width: 100%;
}

.brand-story__button {
  text-align: center;
  padding-top: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.brand-story__image {
  text-align: center;
}

.search--no-results {
  margin: 0 0 15px;
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .brand-story {
    padding: 30px 10px;
  }
}

@media only screen and (max-width: 680px) {
  .brand-story .md-12:last-of-type {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 500.02px) {
  .brand-img img {
    max-height: 224px;
  }
}

.products__item {
  display: flex;
}

.product {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 15px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: border-color 0.3s;
  margin: 3px 5px;
}

.product:hover {
  border-color: #eee;
}

.product.product--is-promo:hover {
  border-color: #ccc;
}

.product__link {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  color: inherit;
}

.product__link:hover {
  text-decoration: none;
}

.product__link:hover .product__name {
  text-decoration: underline;
}

.product__photo {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.product__text {
  flex: 1 0 auto;
}

.product__name {
  margin: 1em 0 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
}

.product__name .product__name-brand {
  font-weight: bolder;
}

.product__detail {
  margin: 5px 0;
  padding: 0;
  font-size: 12px;
}

.product__price {
  margin-top: 15px;
}

.product__quickview {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}

.product:hover .product__quickview {
  opacity: 1;
  pointer-events: auto;
}

@media only screen and (min-width: 460px) {
  .products__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .products__item {
    width: 50%;
  }
  .promo-featured-product .products__item {
    width: calc(100% / 3);
  }
}

@media only screen and (min-width: 840px) {
  .products__item {
    width: calc(100% / 3);
  }
  .promo-featured-product .products__item,
  .best-sellers-product .products__item {
    width: 25%;
  }
}

.product__image, .product__link {
  min-height: 1px;
}

@media only screen and (min-width: 820px) {
  .ui-quickview .prod-info, .has--pencil .ui-quickview .prod-info {
    padding-top: 0;
  }
  .ui-quickview .product-img, .has--pencil .ui-quickview .product-img {
    margin-top: 0;
  }
}

.product-meta {
  display: none;
  visibility: hidden;
}

.product-search {
  padding: 10px 20px;
  max-width: 600px;
}

.product-search ul {
  padding-inline-start: 25px;
}

.prod-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.9em;
}

.prod-list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 0 1.5em;
  padding: 10px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: border-color 0.3s;
}

.prod-list__item:hover {
  border-color: silver;
}

.prod-list__item:nth-child(4n+1) {
  clear: both;
}

.prod-list__item--clear {
  clear: both\9;
}

.prod-list__link {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  color: #333333;
  line-height: 1.3;
  text-decoration: none;
}

.prod-list__link:hover {
  color: inherit;
  text-decoration: none;
}

.prod-list__image {
  flex: 1 auto;
}

.prod-list__image .prod-list__photo {
  max-height: none;
}

.prod-list__photo {
  display: block;
  height: auto;
  max-width: 100%;
  max-height: 150px;
  margin: 0 auto;
}

.prod-list__header {
  margin: 0;
  padding: 1em 0 0;
  font-size: 14px;
  font-weight: normal;
}

.prod-list__size, .prod-list__sku {
  margin: 0;
  padding: 0;
}

.prod-list__price {
  margin: 15px 0;
  padding: 0;
}

.prod-list__description {
  flex: 1 0 auto;
  min-height: 0;
}

.prod-list__quickview {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
}

.prod-list__item:hover .prod-list__quickview {
  opacity: 1;
  pointer-events: auto;
}

@media only screen and (max-width: 780px) {
  .prod-list__item {
    width: 33.33333%;
  }
  .prod-list__item:nth-child(4n+1) {
    clear: none;
  }
  .prod-list__item:nth-child(3n+1) {
    clear: both;
  }
}

@media only screen and (max-width: 500px) {
  .product-search {
    padding: 5px 10px;
  }
  .prod-list__item {
    width: 50%;
  }
  .prod-list__item:nth-child(3n+1) {
    clear: none;
  }
  .prod-list__item:nth-child(odd) {
    clear: both;
  }
}

@media only screen and (max-width: 420px) {
  .prod-list__item {
    width: 100%;
    margin-bottom: 0;
  }
}

.component-call {
  margin: 8px 4px;
}

.component-call--hidden {
  display: none;
  visibility: hidden;
}

.prod-list--vertical .prod-list__item {
  width: 100%;
  float: none;
}

.prod-list__list {
  position: relative;
  width: 100%;
  padding: 10px 0;
}

.list-link {
  color: #333333;
}

.list-link:hover {
  text-decoration: underline;
}

.list-image {
  float: left;
  padding-right: 8px;
  max-width: 75px;
}

.list-name {
  width: 64%;
  padding: 0 5px;
}

.list-name h3 {
  margin: 0;
  padding: 0;
  font-size: 1.15em;
  font-weight: normal;
}

.list-name p {
  margin: 0;
  padding: 0;
}

.list-name .list-sku {
  padding: 2px 0 10px;
  font-size: 1em;
}

.list-cart, .list-price, .list-stock, .list-contract {
  display: inline-block;
  padding: 0 5px;
  text-align: right;
  vertical-align: middle;
}

.list-cart {
  padding-top: 5px;
}

.list-name, .list-pricing {
  display: inline-block;
  vertical-align: middle;
}

.list-pricing {
  width: 35%;
  text-align: right;
}

.list-actions--top {
  border-bottom: 1px solid silver;
}

@media only screen and (max-width: 480px) {
  .list-pricing {
    text-align: right;
  }
  .product-content .list-name {
    width: 100%;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
  .product-content .list-image {
    max-width: none;
  }
  .product-content .list-pricing {
    width: 100%;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: auto;
    justify-items: right;
  }
  .product-content .prod-list__list {
    display: grid;
    grid-template-columns: 7fr 3fr;
    grid-gap: 0px;
    margin: 10px 0;
    align-items: start;
  }
}

@media only screen and (max-width: 420px) {
  .product-content .list-price {
    max-width: 80px;
  }
  .product-content .prod-list__list {
    grid-template-columns: 2fr 1fr;
  }
}

.product-list-inner {
  position: relative;
}

.prod-options-wrap, .related-prod-wrap {
  padding-top: 30px;
}

.prod-options-inner, .related-prod-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: none;
  overflow-y: visible;
}

.related-prod-inner {
  max-height: 300px;
}

.prod-option, .related-prod {
  position: relative;
  width: 20%;
  margin: 0 0 10px;
  padding: 4px;
  text-align: center;
  border: 1px solid transparent;
}

.related-prod {
  width: 50%;
}

.prod-options-inner .prod-list__item, .prod-options-inner .prod-list__item:hover {
  border: none;
}

.prod-option:hover, .related-prod:hover {
  border-color: black;
}

.prod-option .onsale, .related-prod .onsale {
  margin: 5px 0;
}

.prod-option:nth-child(5n+1) {
  clear: both;
}

.prod-option--clear {
  clear: both\9;
}

.related-prod:nth-child(odd) {
  clear: both;
}

.related-prod--clear {
  clear: both\9;
}

.prod-option-desc {
  padding: 2px;
}

.prod-option-name {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  font-weight: bold;
}

.prod-option-size {
  display: block;
  font-size: 0.9em;
  font-weight: normal;
}

.dialog-create-list {
  padding: 15px 0 0;
}

.dialog-create-list .button, .dialog-select-list .button {
  margin-top: 5px;
}

.prod-options-cart {
  margin: 5px 0;
}

.prod-option p {
  margin: 0;
  padding: 10px 0 0;
}

.related-prod-link {
  display: block;
  color: #333333;
}

.related-prod-link:hover {
  text-decoration: underline;
}

.related-prod .prod-option-name {
  margin: 0 0 5px;
}

.prod-option--added, .prod-option--added:hover {
  border: 1px solid #338326 !important;
}

@media only screen and (max-width: 860px) {
  .prod-option {
    width: 25%;
  }
  .prod-option:nth-child(5n+1) {
    clear: none;
  }
  .prod-option:nth-child(4n+1) {
    clear: both;
  }
}

@media only screen and (max-width: 740px) {
  .prod-option {
    width: 33.33333333%;
  }
  .prod-option:nth-child(4n+1) {
    clear: none;
  }
  .prod-option:nth-child(3n+1) {
    clear: both;
  }
}

@media only screen and (max-width: 540px) {
  .prod-option {
    width: 50%;
  }
  .prod-option:nth-child(3n+1) {
    clear: none;
  }
  .prod-option:nth-child(odd) {
    clear: both;
  }
}

@media only screen and (max-width: 380px) {
  .related-prod {
    float: none;
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .prod-option, .related-prod {
    float: none;
    width: 100%;
  }
}

.list-view, .grid-view {
  flex: 1 0 auto;
  display: inline-block;
  padding: 3px 5px 3px 0;
  color: #333;
  text-align: center;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.list-view:hover, .grid-view:hover {
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s;
}

.list-view__icon, .grid-view__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("/images/sprite.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.list-view__label, .grid-view__label {
  display: block;
  color: inherit;
  font-size: 11px;
  line-height: 0.7;
  text-transform: uppercase;
}

.grid-view__icon {
  background-position: -24px 0;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9001;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 350px;
  margin: auto;
  padding: 15px;
  color: #ccc;
  text-align: center;
  background: #4b4b4b;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
}

.modal a {
  color: white;
}

.modal--spinner {
  max-height: 200px;
}

.modal--timeout {
  top: 30%;
  right: 2%;
  bottom: auto;
  left: 2%;
}

.spinner__header {
  margin: 10px 0 0;
  padding: 0;
}

.spinner-panel {
  display: block;
  position: absolute;
  z-index: 900;
  top: 75px;
  bottom: auto;
  width: 94px;
  max-height: 94px;
}

@media only screen and (max-width: 420px) {
  .modal--spinner {
    top: 50%;
    right: 5%;
    bottom: auto;
    left: 5%;
    width: auto;
    max-height: none;
    transform: translateY(-50%);
  }
  .spinner-panel {
    top: 200px;
  }
}

.banner-spinner {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 32px;
  margin: -16px auto 0;
}

.banner-spinner img {
  width: auto;
  max-width: 100%;
}

.spinner--view {
  display: block;
  position: absolute;
}

.prod-info__header {
  padding: 0 0 0.75em;
  border-bottom: 1px solid #ddd;
}

.prod-info__name {
  margin: 0;
  padding: 0;
  color: #333333;
  font-weight: normal;
  line-height: 1.15;
  text-transform: uppercase;
}

.prod-info__brand {
  margin: 0;
  padding: 0;
  color: #333333;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1;
}

.prod-info__data {
  margin: 0.75em 0 0;
}

.prod-info__item {
  margin: 0 0 0.25em;
}

.prod-info__price-note {
  margin-top: 0;
  font-size: 0.85em;
  font-weight: bold;
}

.prod-info__no-price {
  margin: 10px 0;
}

.prod-info__marketplace {
  margin: 0.5em 0 0.25em;
  font-size: 0.9em;
}

.prod-info__short-desc {
  display: block;
  margin-top: 1.5em;
  line-height: 1.6;
}

.prod-info__full {
  font-size: 1.25em;
}

.prod-info--view .prod-info__name {
  font-size: 1.25em;
}

.prod-info--view .prod-info__short-desc {
  margin-top: 0.5em;
}

.prod-detail-wrap {
  padding-top: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.prod-detail-wrap h3 {
  margin: 0.25em 0 0;
  padding: 0;
  font-size: 1.17em;
}

@media only screen and (max-width: 480px) {
  .prod-detail-wrap {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.price {
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
}

.price--small {
  font-size: 11px;
  line-height: 1.2;
}

.price--large {
  font-size: 14px;
}

.price__label,
.price__label--salon,
.price__label--sale,
.price__label--savings {
  display: block;
  margin: 0.2em 0;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
}

.price__label--salon {
  color: #aaaaaa;
  font-weight: normal;
}

.price__label--sale,
.price__label--savings {
  color: #d32121;
}

.price__label--highlight {
  background-color: #d32121;
  color: white;
  font-size: larger;
  padding: 2px;
}

.price__label--savings {
  font-style: italic;
  text-transform: none;
}

.price__sizes {
  font-weight: bold;
}

.price__current {
  font-size: 3em;
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
}

.price--small .price__current {
  font-size: 1.8em;
}

.price--compact .price__label, .price--compact .price__label--sale {
  display: inline;
}

.price--compact .price__current {
  vertical-align: baseline;
}

.for-price, .for-price--large {
  color: #333333;
  font-size: 1.6em;
  text-decoration: underline;
}

.for-price, .for-price:hover {
  font-size: 1em;
  font-weight: bold;
}

.prod-pricing {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
  padding: 5px 0;
}

.prod-pricing::before, .prod-pricing::after {
  display: block;
}

.prod-pricing .prod-qty label {
  margin-top: -1.25em;
}

.js--add-list {
  margin-bottom: 0;
}

@media only screen and (max-width: 680px) {
  .prod-add {
    margin: 15px 0 0;
  }
}

.prod-price {
  position: relative;
}

.prod-price__inner {
  align-items: center;
}

.prod-price__price {
  grid-row: 1 / span 2;
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
}

.prod-price__qty {
  grid-row: 1 / span 2;
  flex: 1 0 auto;
  padding: 10px;
  text-align: center;
  transform: translateY(-0.5em);
}

.prod-price__qty .input--qty {
  font-size: 2em;
  font-weight: bold;
}

.prod-price__add {
  flex: 1 1 100%;
  margin-top: auto;
  padding: 0 10px;
  text-align: center;
}

.prod-price__add .button {
  margin-bottom: 0;
}

.prod-price__list {
  grid-column: 3;
  margin-top: 5px;
  margin-bottom: auto;
  padding: 0 10px;
}

@media only screen and (min-width: 540px) {
  .prod-price__inner {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 10px;
  }
}

.prod-price__notify {
  display: inline;
}

.increment {
  margin: 10px 0;
}

.quantity-template {
  display: none;
  visibility: hidden;
}

.product-tables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-table {
  flex: 1 1 50%;
  margin: 20px 0;
}

.quantity-break {
  flex: 1 0 50%;
}

.product-table__header {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 1.15em;
}

.accordion {
  margin-top: 10px;
}

.accordion__section {
  border-top: 1px solid #ddd;
}

.accordion__section:last-child {
  border-bottom: 1px solid #ddd;
}

.accordion__toggle {
  display: block;
  position: relative;
  width: 100%;
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #4c4c4c;
}

.accordion__toggle::after {
  content: '\25bc';
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 12px;
  line-height: 16px;
  transform: translateY(-50%);
}

.accordion__section--active .accordion__toggle::after {
  content: '\25b2';
}

.accordion__content {
  display: none;
  padding: 15px 10px;
}

.accordion__section--active .accordion__content {
  display: block;
}

/*@import 'lib/components/page-share';*/
.product-img {
  margin: 0 0 15px;
}

.product-img img, .product-img__feature {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.product-img__thumbs {
  display: table;
  margin: 10px auto;
}

.product-thumb {
  float: left;
  width: 75px;
  height: 75px;
  margin: 0 5px;
  border: 1px solid transparent;
}

.product-thumb--selected {
  border: 1px solid #333333;
}

.product-thumb:hover {
  border: 1px solid #aaaaaa;
}

.product-thumb--selected:hover {
  border: 1px solid #333333;
}

.component-image {
  position: relative;
}

.component-image__zoom {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
  width: 24px;
  height: 24px;
  line-height: 1;
  background: none;
  background-image: url("/images/search.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  font-size: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.component-image:hover .component-image__zoom {
  opacity: 1;
}

.bxgy__section {
  margin: 15px 0;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.bxgy__header {
  width: 100%;
  padding: 30px;
  font-size: 20px;
  text-align: center;
  background-color: #272727;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.bxgy-product {
  display: grid;
  width: 100%;
  text-align: center;
  vertical-align: top;
  padding: 15px 0;
  font-size: 11px;
  border-top: 1px solid #ddd;
  grid-template-columns: auto 1fr auto;
  grid-gap: 5px;
}

.is--logged-out .bxgy-product {
  grid-template-columns: auto 1fr 70px;
}

.bxgy-product:first-of-type {
  border-top: none;
}

.bxgy__price {
  text-align: center;
  width: 100%;
  margin: -10px 0 10px;
}

.bxgy__add, .bxgy__qty {
  margin: 10px;
  text-align: center;
}

.bxgy__add .button, .bxgy__qty .button {
  border: 2px solid #fff;
}

.bxgy__qty span {
  font-weight: bold;
  padding-right: 2px;
}

.bxgy-product__photo {
  grid-column: 1;
}

.bxgy-product__header {
  grid-column: 2;
  text-align: left;
}

.bxgy-product__title {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.4;
}

.bxgy-product__get-pricing,
.bxgy-product__pricing {
  grid-column: 3;
  padding-right: 5px;
}

.bxgy-product__pricing {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 50px;
  align-items: center;
  min-height: 55px;
}

.bxgy-product__get-pricing {
  margin-top: 15px;
  text-align: center;
}

.bxgy-product__price, .bxgy-product__qty {
  text-align: center;
}

.bxgy-product__price-label {
  display: block;
  margin-bottom: 2px;
}

.bxgy-product__price-value {
  font-size: 18px;
  font-weight: bold;
}

.bxgy-product__no-stock {
  text-align: center;
}

.bxgy__quantities-container {
  bottom: 0px;
  position: sticky;
  opacity: 1;
  background: #272727;
  width: 100%;
  max-width: 1000px;
  z-index: 900;
  padding: 10px 15px;
  color: #fff;
  align-items: start;
  display: grid;
  font-size: 1.1em;
  grid-template-columns: auto;
  grid-column-gap: 5px;
}

.bxgy__quantities {
  display: grid;
  text-align: center;
  padding: 5px 0;
}

.bxgy-quantity {
  padding: 2px 0;
  text-align: center;
}

.bxgy-quantity--label {
  display: block;
  text-decoration: underline;
  font-weight: 700;
  grid-row: 1;
  grid-column: 1 / span 2;
}

.bxgy-quantity--selected {
  grid-row: 2;
  grid-column: 1 / span 2;
}

.bxgy-quantity--required {
  font-weight: bold;
  grid-row: 3;
  grid-column: 1 / span 2;
}

.bxgy__add-to-cart {
  grid-row: 2;
  grid-column: 1 / span 2;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.bxgy__error-container {
  grid-row: 3;
  grid-column: 1 / span 2;
  text-align: center;
}

.bxgy__error.lbl--error,
.bxgy__error.lbl--success {
  display: block;
  margin: 5px 0;
}

@media only screen and (max-width: 480px) {
  .bxgy__quantities {
    text-align: left;
  }
  .bxgy-quantity {
    text-align: left;
  }
}

@media only screen and (min-width: 680.02px) {
  .bxgy__price {
    width: fit-content;
  }
  .bxgy {
    padding: 0 10px;
  }
  .bxgy__header {
    font-size: 24px;
  }
  .bxgy-product {
    display: inline-grid;
    grid-gap: 5px;
    width: 49%;
    border: none;
    padding: 20px 0;
  }
}

@media only screen and (min-width: 820px) {
  .bxgy__inner {
    padding: 0 10px;
  }
  .bxgy-product {
    grid-gap: 10px;
    padding: 20px 5px;
  }
  .bxgy__quantities-container {
    grid-template-columns: 1fr 1fr auto;
    align-items: center;
    padding: 5px 20px;
  }
  .is--logged-out .bxgy__quantities-container {
    grid-template-columns: 1fr 1fr;
  }
  .bxgy__add-to-cart {
    grid-row: 1;
    grid-column: 3;
  }
  .bxgy__error-container {
    grid-row: 2;
    grid-column: 1 / span 3;
  }
  .is--logged-out .bxgy__error-container {
    grid-column: 1 / span 2;
  }
  .bxgy-quantity {
    padding: 2px 5px;
  }
  .bxgy-quantity--selected {
    grid-column: 1;
    text-align: right;
    padding-right: 15px;
  }
  .bxgy-quantity--required {
    grid-row: 2;
    grid-column: 2;
    text-align: left;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 920px) {
  /*.bxgy-product__pricing {
		grid-column: 3 / span 2;
		grid-gap: 10px;
		margin-left: 15px;
	}*/
}

.deal-sku__link {
  color: #00bfff;
  font-weight: bold;
  text-decoration: underline;
}

.external {
  padding: 5px 0 0;
  margin: 0 0 5px;
}

.external__link {
  color: #333333;
  font-weight: bold;
}

.external__img, .external__price {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 0;
  vertical-align: middle;
}

.external__price:hover {
  text-decoration: underline;
}

.tip_trigger {
  cursor: pointer;
}

.tip {
  position: absolute;
  z-index: 20001;
  min-width: 175px;
  padding: 10px;
  color: black;
  background: white;
  border: 1px solid #333333;
  border-radius: 3px;
}

.tip_trigger .tip {
  display: none;
}

.checkout .tip_trigger {
  color: #005ca0;
  text-decoration: underline;
}

.quick-modal, .add-cart-dialog {
  display: none;
}

.ui-widget, .quick-modal {
  font-size: 1em;
}

.quick-error {
  font-weight: bold;
}

.ui-dialog .ui-dialog-title {
  display: none;
}

.ui-dialog-titlebar {
  background: none;
  border: none;
}

.ui-dialog {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ui-dialog .ui-dialog-titlebar-close {
  top: 0;
  right: 5px;
}

.ui-dialog .ui-dialog-buttonpane {
  display: none;
}

@media only screen and (max-width: 540px) {
  .quick-modal {
    font-size: 0.85em;
  }
}

.nostock {
  font-size: 1.2em;
  font-weight: bold;
}

.nostocksmall {
  font-size: 1em;
  font-weight: bold;
  line-height: 22px;
}

.nostocklarge {
  font-size: 1.5em;
  font-weight: bold;
}

.list-tbl .nostock {
  max-width: 70px;
  font-size: 14px;
  text-align: center;
}

.prod-option .nostocksmall,
.related-prod .nostocksmall {
  display: block;
  margin: 5px 0;
}

@media only screen and (max-width: 480px) {
  .nostock {
    font-size: 1em;
  }
  .nostocksmall {
    font-size: 0.9em;
  }
}

.message--added {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background: #338326;
  background: rgba(51, 131, 38, 0.8);
}

.message--added > span {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 0 10%;
  text-align: center;
  transform: translateY(-50%);
}

.locator {
  display: flex;
  flex-direction: column;
}

.locator__inner {
  order: 1;
}

.locator__map {
  order: 2;
}

.locator-results {
  max-height: 25vh;
  overflow-y: auto;
}

@media only screen and (min-width: 800px) {
  .locator {
    display: block;
  }
}

.locator {
  position: relative;
  margin-top: 15px;
}

.locator__map {
  position: relative;
  padding-bottom: 76.1904%;
  overflow: hidden;
}

.locator-input__submit {
  position: relative;
  align-self: flex-end;
}

.locator-search__submit {
  display: block;
  width: 32px;
  height: 28px;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 16px;
  background-image: url("/images/search.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  border: none;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (min-width: 800px) {
  .locator__inner {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 325px;
    z-index: 300;
  }
  .locator__inner--left {
    right: auto;
    left: 10px;
  }
}

.locator-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  column-gap: 5px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #ededed;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.locator-input__label {
  display: block;
  margin-bottom: 2px;
  padding: 0 3px;
}

.locator-input__location {
  flex: 1;
}

.locator-search__text, .locator-search__range, .locator-search__submit {
  height: 28px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.locator-search__text {
  width: 100%;
  margin: 0;
}

.locator-search__range {
  max-width: 100px;
  padding: 5px 20px 5px 5px;
  background-color: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+);
  background-position: 95% 50%;
  background-repeat: no-repeat;
  border: 1px solid #ddd;
  border-radius: 3px;
  appearance: none;
}

.locator-result {
  padding-left: 10px;
  font-size: 0.9em;
}

.locator-result__icon {
  float: left;
  overflow: hidden;
}

.locator-result__info {
  padding: 1px 10px;
  overflow: hidden;
}

.locator-result__section {
  padding: 3px 0;
  line-height: 1.5;
  overflow: hidden;
}

.icon--map-home {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/map/home-map-marker.png") no-repeat 0 0;
  background-size: 18px 18px;
  opacity: 0.65;
}

.icon--phone {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/map/phone.png") no-repeat 0 0;
  background-size: 18px 18px;
  opacity: 0.65;
}

.icon--clock {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/map/clock.png") no-repeat 0 0;
  background-size: 18px 18px;
  opacity: 0.65;
}

.icon--pin {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 22px;
  height: 40px;
  padding: 3px 1px 0 0;
  font-size: 1.15em;
  text-align: center;
  background: url("/images/map/pin.png") no-repeat 0 0;
}

.icon--car {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/map/car.png") no-repeat 0 0;
  background-size: 18px 18px;
  opacity: 0.65;
}

.icon--link {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/map/link.png") no-repeat 0 0;
  background-size: 18px 18px;
  opacity: 0.65;
}

.locator-results__list {
  margin: 0;
  padding: 10px 0;
  list-style: none;
}

.locator-results__item {
  position: relative;
  padding: 10px 10px 10px 30px;
}

.locator-result__name {
  margin: 0 0 5px;
  padding: 0;
  font-size: 1.15em;
}

@media only screen and (min-width: 800px) {
  .locator-results {
    max-height: 31em;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 3px;
    overflow-y: auto;
  }
}

.locator__count {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  overflow: hidden;
}

.locator__count > p {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 800px) {
  .locator__count {
    margin: 0 0 5px;
    padding: 10px;
    border-radius: 3px;
  }
}

.locator__count-pin {
  float: left;
  display: block;
  width: 14px;
  height: auto;
  margin-right: 15px;
}

.locator-results__list--highlighted .locator-results__item {
  opacity: 0.4;
}

.locator-results__list--highlighted .locator-results__item--highlight {
  opacity: 1;
}

.gm-style-iw .icon--pin {
  display: none;
}

.locator-filters {
  padding: 5px;
  margin-bottom: 5px;
  background: #fff;
  border: 1px solid #ddd;
}

.locator-filters__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.locator-filters__item {
  padding-bottom: 10px;
}

.locator-filters__toggle {
  display: block;
  padding: none;
  line-height: 1;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.locator-filters__toggle::before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 20px;
  margin-right: 5px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 11px 20px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.locator-filters__toggle--inactive {
  opacity: 0.5;
}

.education-content {
  max-width: 1000px;
  margin: 0 auto;
}

.education-filters {
  padding-top: 5px;
}

.education-all {
  padding: 0 0 0 15px;
}

.education-all__header {
  margin: 0;
  padding: 5px 0 0.5em;
  font-size: 1.25em;
}

.education-all__filters {
  margin: 5px;
  padding: 5px 0;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 0;
}

.education-all__call {
  text-align: right;
}

.education-all__filters .row {
  margin-right: 0;
  margin-left: 0;
}

@media only screen and (max-width: 680px) {
  .education-all {
    margin-top: 15px;
    padding: 0 10px;
  }
}

.edu-list-item__title a {
  color: #000;
}

.edu-list-item__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  height: auto;
}

.edu-list-item__image-overlay {
  width: 100%;
}

.edu-list-item__dates {
  font-size: .9em;
}

@media only screen and (max-width: 680px) {
  .edu-list-item__title {
    margin-top: 10px;
  }
}

.education-class__flyer {
  display: flex;
  align-items: center;
}

.education-class__flyer img {
  max-width: 22px;
}

.is--logged-in .education-jotform {
  display: none;
}

.education-all .class-dates {
  width: 100%;
}

.class-dates {
  max-width: 100%;
  margin: 10px 0;
  border-collapse: collapse;
}

.class-dates th, .class-dates td {
  padding: 5px 10px;
  line-height: 1.4;
}

.class-dates__date {
  width: 100px;
  text-align: center;
}

.class-dates__location {
  width: 180px;
}

.class-dates__seats {
  width: 90px;
  text-align: center;
}

.class-dates__price {
  width: 90px;
  text-align: right;
}

.class-dates__flyer {
  width: 90px;
}

.class-dates__register {
  width: 125px;
  text-align: center;
}

.class-dates__add {
  white-space: nowrap;
}

.class-dates__time {
  font-size: 0.8em;
}

.class-dates__time--from {
  display: block;
}

.class__directions {
  display: block;
  position: relative;
  margin: 5px 0;
  padding: 0 0 0 24px;
  font-size: 11px;
  line-height: 20px;
}

.class__directions::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  background: url("/images/map/car.png") no-repeat 0 0;
  background-size: 18px 18px;
  opacity: 0.65;
}

@media only screen and (max-width: 420px) {
  .class-dates {
    width: 100%;
  }
  .class-dates tr {
    display: flex;
    flex-direction: column;
  }
  .class-dates th {
    display: none;
  }
  .class-dates td {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left !important;
    vertical-align: top;
  }
  .class-dates td::before {
    content: attr(data-hdr);
    display: block;
    font-weight: bold;
  }
  .class-dates .class-dates__name {
    order: -1;
    padding-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.2;
  }
  .class-dates .class-dates__name::before {
    font-size: 13px;
  }
  .class-dates .class-dates__date {
    padding-bottom: 0;
    text-align: left;
  }
  .class-dates__time--from {
    display: inline;
  }
}

.register-have {
  padding-bottom: 10px;
}

.reg-label {
  width: 100px;
  text-align: right;
}

.reg-field {
  width: 140px;
}

.forgot-form {
  padding: 0 0 10px;
}

.forgot-form .button {
  margin-top: 5px;
}

.registererror {
  font-weight: bold;
}

.thankyou {
  font-size: 1.5em;
}

.register {
  padding: 20px 10px;
}

.register__description {
  margin: 10px 0;
}

.register .row {
  margin: 0 -10px;
}

.register h3 {
  margin: 15px 0;
}

.register h3 label {
  font-size: 0.8em;
  font-weight: normal;
}

.register .input--full, .register .input--sm {
  margin-bottom: 5px;
}

@media only screen and (max-width: 420px) {
  .register h3 label {
    display: block;
  }
}

.quick-register .button {
  margin-top: 10px;
}

.login {
  padding: 30px 0 0;
}

.login-section {
  padding: 25px 15px;
  text-align: center;
  width: 100%;
}

.login-section--login {
  padding: 15px 15px;
}

.login-section--register,
.login-section--apply {
  border-top: 1px solid #333333;
}

.login-section__header {
  font-size: 2em;
  font-weight: bold;
}

.login-section__description {
  line-height: 1.7;
  font-size: 18px;
}

.login-section__actions {
  margin-top: 20px;
}

.login-section__actions .button {
  margin-right: 10px;
  margin-left: 10px;
}

.login-resp {
  font-weight: bold;
}

.login-form {
  padding-bottom: 10px;
}

@media only screen and (min-width: 720px) {
  .login {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .login-section {
    width: 33%;
    padding: 15px 30px;
  }
  .login-section--login {
    padding: 15px 30px;
  }
  .login-section--register,
  .login-section--apply {
    border-top: none;
  }
}

.forgot-login-panels {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.forgot-login-panels p {
  align-self: center;
}

.forgot-login-panels .forgot-panel-container {
  margin: 10px 6px;
  padding: 20px;
  height: 230px;
}

.forgot-login-panels .forgot-panel--login, .forgot-login-panels .forgot-panel--password {
  background: transparent;
  border: 1px solid #eee;
}

.forgot-login-panels .forgot-input {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  grid-gap: 6px;
}

@media (max-width: 480px) {
  .forgot-login-panels .forgot-panel-container {
    padding: 20px 8px;
  }
  .forgot-login-panels .forgot-password-input--container div {
    margin: 6px 0;
  }
}

@media only screen and (min-width: 1000px) {
  .login-form__input input {
    min-width: 250px;
  }
}

.login-form__row {
  margin-bottom: 10px;
  padding: 5px 0;
}

.login-form__label {
  display: block;
  padding: 5px 5px 0 0;
}

.login-form__input {
  width: 100%;
  padding: 5px 0;
}

.login-form__field {
  width: 100%;
  max-width: 150px;
  padding: 8px;
  border: 1px solid #c0c0c0;
  border-radius: 2px;
}

.login-form__actions {
  padding: 15px 0;
}

.login-form__forgot {
  font-size: 11px;
}

.not-found {
  background-color: #f6d4d4;
  border: 2px solid #bd6666;
  border-radius: 2px;
  padding: 5px;
}

.not-found-header {
  margin: 0;
  padding: 15px 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.not-found-text {
  font-size: 16px;
  text-align: center;
}

.register-found {
  text-align: left;
  vertical-align: top;
}

.register-found-form {
  background-color: #d2ffca;
  border: 2px solid #549549;
  border-radius: 2px;
}

.found-header {
  margin: 0;
  padding: 15px 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.register-found h5 {
  margin: 0;
  padding: 0 0 0.5em;
  font-size: 1.15em;
}

.register-found__requirements {
  margin: 5px 0 15px;
}

.register-found .faux-table {
  margin: 15px 0 0;
}

.found-inner {
  padding: 10px 10px 0;
}

.found-continue {
  padding: 10px 0 0;
}

.quick-connect__header {
  margin: 30px 0;
}

.quick-connect__form {
  max-width: 300px;
}

.quick-connect__image--desktop {
  display: none;
}

@media only screen and (min-width: 768px) {
  .quick-connect {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .quick-connect__header {
    flex: 1;
    order: 2;
    margin-top: 0;
    padding-left: 30px;
  }
  .quick-connect__image--mobile {
    display: none;
  }
  .quick-connect__image--desktop {
    display: block;
  }
}

.modal--forgot .ui-dialog-titlebar {
  margin: 0 10px;
  padding: 10px 5px;
  font-size: 16px;
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ddd;
}

.modal--forgot .ui-dialog-titlebar-close {
  background: none;
  border: none;
}

.modal--forgot .ui-dialog-content {
  line-height: 1.7;
}

.forgot-form .button {
  margin-top: 10px;
}

.register-options {
  margin: 0 -10px;
  overflow: hidden;
}

.register-options__choice {
  float: left;
  width: 50%;
  padding: 0 10px;
  text-align: center;
}

@media only screen and (max-width: 420px) {
  .register-options {
    margin: 0;
  }
  .register-options__choice {
    float: none;
    width: 100%;
  }
}

.contact__form {
  padding: 15px 0 0;
}

.contact__item {
  margin-bottom: 15px;
}

.contact__status {
  display: block;
  padding: 15px 0 5px 0;
  font-size: 1.15em;
}

.RadCaptcha {
  margin: 0 0 5px;
}

.tabs {
  margin: 30px 0 0;
}

.tabs-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

.tabs-nav > li {
  float: left;
  margin-right: 5px;
}

.tabs-nav a {
  display: block;
  padding: 8px 16px;
  color: #333333;
  line-height: 1;
  background: #eeeeee;
  border: 1px solid #ddd;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
}

.tabs-nav a:hover {
  text-decoration: none;
}

.tabs-nav .tabs-nav--active {
  color: white;
  background: black;
}

.tabs-content {
  min-height: 250px;
  padding: 15px;
}

.page-header {
  margin: 0 auto;
  padding: 10px 10px 0;
  overflow: auto;
}

.main-header {
  float: left;
}

.page-help {
  float: right;
}

.page-help p {
  margin: 0;
  padding: 0;
}

.page-wrap {
  padding-top: 15px;
  padding-bottom: 15px;
}

.page-content {
  font-size: 14px;
  line-height: 2;
}

.page-content p {
  margin: 0;
  margin-bottom: 2em;
  padding: 0;
}

.page-content h3 {
  font-size: 1em;
}

.page-content img {
  display: block;
  max-width: 100%;
  height: auto;
}

.category-header {
  margin: 0;
  padding: 2px;
  font-size: 1em;
  font-weight: bold;
}

.category-links {
  margin: 0 0 20px;
}

.category-link {
  padding: 3px 0 0 10px;
}

.category-links a {
  color: #4c4c4c;
}

.category-links a:hover {
  color: #333333;
}

.category-links .selected {
  color: #333333;
  font-weight: bold;
}

@media only screen and (max-width: 680px) {
  .page-sections .filters__list > li {
    float: none;
    width: auto;
  }
}

@media only screen and (max-width: 680px) {
  .page-wrap {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 420px) {
  .page-wrap {
    margin-right: 0;
    margin-left: 0;
    padding: 15px 0 0;
  }
  .page-header {
    padding: 10px 5px 0;
  }
  .page-help {
    clear: both;
    float: none;
    margin: 15px 0;
  }
  .page-sections .filters {
    padding-top: 30px;
  }
}

.catalogs__header {
  margin: 0;
  padding: 10px;
  color: #333333;
  font-size: 1.8em;
  font-weight: normal;
  text-transform: uppercase;
}

.catalog {
  margin-top: 15px;
  padding: 0 10px;
  border-bottom: 1px solid #efefef;
}

.catalog:first-child {
  margin-top: 0;
}

.catalog:last-child {
  border-bottom: 1px solid #ccc;
}

.catalog__link {
  display: block;
  overflow: hidden;
}

.catalog__link:hover {
  text-decoration: none;
}

.catalog__image {
  display: inline-block;
  margin-bottom: -12px;
}

.catalog__text {
  display: inline-block;
  padding: 8px;
  font-size: 1.2em;
  font-weight: bold;
}

.catalog__text::after {
  content: '';
  display: inline-block;
  margin: 0 0 -5px 5px;
  width: 24px;
  height: 24px;
  background-image: url("/images/arrow-blue.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.catalog__link:hover .catalog__text {
  text-decoration: underline;
}

@media only screen and (min-width: 640px) {
  .catalogs__header {
    padding-bottom: 0;
  }
  .catalogs__list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
  }
  .catalog {
    flex: 0 1 auto;
    width: 50%;
  }
  .catalog:first-child {
    margin-top: 15px;
  }
  .catalog:last-child {
    border-bottom: 1px solid #efefef;
  }
}

@media only screen and (min-width: 1000px) {
  .catalogs {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    border-top: 1px solid #ddd;
  }
  .catalogs__header {
    flex: 0 0 auto;
    align-self: flex-end;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid #efefef;
  }
  .catalogs__list {
    flex: 1 1 auto;
  }
  .catalog {
    flex: 1 1 auto;
  }
}

.quick-order {
  margin-top: 15px;
}

.quick-section {
  display: inline-block;
}

.quick-list {
  padding-top: 17px;
}

.quick-list .form-el {
  width: 100%;
}

.quick-section__search {
  display: none;
}

@media only screen and (max-width: 680px) {
  .quick-list {
    padding-top: 10px;
  }
  .quick-list .row {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .quick-list .button {
    display: block;
    margin: 0 0 5px;
    text-align: center;
  }
  .quick-section input {
    font-size: 16px;
    line-height: 1.2;
  }
  .quick-section input::placeholder {
    font-size: 16px;
  }
}

.credit-card {
  display: inline-block;
  width: 73px;
  height: 44px;
  background-image: url("/images/credit-cards.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.4s;
}

.credit-card--visa {
  background-position: 0 0;
}

.credit-card--mastercard {
  background-position: -73px 0;
}

.credit-card--discover {
  background-position: -146px 0;
}

.credit-card--amex {
  background-position: -219px 0;
}

.credit-cards--highlighted .credit-card {
  opacity: 0.4;
}

.credit-cards--highlighted .credit-card--highlight {
  opacity: 1;
}

.rightsign-link {
  margin: 0;
  padding-top: 0;
  padding-bottom: 30px;
}

.verify-billing {
  max-width: 400px;
  margin: 45px 0 30px;
}

.verify-billing__header {
  font-size: 1.25em;
}

.verify-billing__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -5px;
  overflow: hidden;
}

.verify-billing__field {
  flex: 10 10 100%;
  padding: 5px;
}

@media only screen and (min-width: 460px) {
  .verify-billing__field--city {
    flex: 5 5 50%;
  }
  .verify-billing__field--state {
    flex: 2 2 20%;
  }
  .verify-billing__field--zip {
    flex: 3 3 30%;
  }
}

.coupon {
  margin: 0 0 10px;
}

.coupon__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.coupon__button {
  margin-left: 10px;
}

.checkout-header-wrap {
  padding-top: 10px;
}

.checkout select {
  padding: 2px;
}

.checkout h3 {
  margin: 0 0 0.25em;
  font-size: 1em;
}

.checkout .checkout-info__header {
  margin: 14px 0 7px;
  line-height: normal;
}

.checkout-progress-bar {
  margin: 10px 0 0;
  background-color: black;
  overflow: auto;
}

.checkout-progress {
  float: left;
  padding: 8px 16px;
  color: #999999;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.checkout-progress-selected {
  font-weight: bold;
  color: white;
}

.checkout-error {
  display: block;
  padding: 5px 0;
  font-weight: bold;
}

.checkout-col {
  padding: 10px 0 20px;
}

.checkout-header {
  margin: 0;
  padding: 0 0 2px;
  font-size: 1.1em;
  font-weight: bold;
}

.select-payment {
  display: inline-block;
  vertical-align: middle;
}

.payment-label {
  padding-top: 20px;
}

.payment-option {
  padding: 0 0 15px;
  font-size: 1.15em;
}

.payment-option td {
  padding-right: 25px;
}

.checkout-info {
  padding-bottom: 30px;
}

.checkout-note {
  padding-bottom: 15px;
}

.checkout-tbl {
  width: 100%;
  margin: 10px 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.checkout-tbl th {
  padding: 5px 10px;
  text-align: left;
  background: silver;
}

.checkout-tbl td {
  padding: 5px 10px;
}

.checkout-tbl .sm-col--price {
  white-space: nowrap;
}

.checkout .button--checkout {
  float: right;
  margin: 0 10px 5px 0;
}

.tbl-line td {
  border-top: 1px solid silver;
}

.checkout-tbl tbody tr:nth-child(even) td {
  background: #eeeeee;
}

.cart-total td {
  padding-top: 10px;
  font-size: 1.4em;
  font-weight: bold;
  border-top: 1px solid silver;
}

.cart-product {
  position: relative;
  padding-left: 24px;
}

.button--cart-remove {
  position: absolute;
  color: #888;
  font-weight: bold;
  text-transform: lowercase;
}

@media only screen and (max-width: 480px) {
  .checkout-tbl {
    border-top: 1px solid silver;
  }
  .checkout-tbl thead {
    display: none;
  }
  .checkout-tbl td {
    display: block;
    width: 100%;
  }
  .checkout-sku::before {
    content: attr(data-hdr);
    display: inline;
    font-weight: bold;
  }
  .tbl-line {
    display: block;
    border-top: 1px solid silver;
  }
  .tbl-line td {
    border: none;
  }
  .checkout-tbl tr {
    display: block;
    clear: both;
    overflow: hidden;
  }
  .checkout-tbl tfoot tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .checkout-tbl tfoot td {
    float: left;
    width: auto;
    text-align: left;
  }
  .checkout-tbl tfoot td[colspan="3"] {
    grid-column: 1 / span 2;
  }
  .checkout-tbl tbody tr {
    padding: 10px 0;
  }
  .checkout-tbl tbody tr:nth-child(even) {
    background: #eeeeee;
  }
  .checkout-tbl .checkout-disclaimer {
    grid-column: 1 / span 2;
  }
  .checkout .button--checkout {
    float: none;
    margin-left: 10px;
  }
  .checkout-tbl .sm-col {
    float: left;
    width: 33.33333333%;
    /*text-align: left !important;*/
  }
}

.lookup-wrap {
  padding: 0 10px;
  margin-bottom: 30px;
  overflow: auto;
}

.lookup-legend {
  font-weight: normal;
}

.lookup-filters {
  margin: 0 auto 15px;
}

.lookup-wrap label {
  display: block;
  margin: 0 0 2px;
}

.lookup-wrap .RadComboBox, .lookup-wrap .RadPicker {
  margin: 0 0 5px;
}

.lookup-filter-label {
  display: inline-block;
  min-width: 85px;
  text-align: right;
}

.order-detail-wrap {
  max-height: 71.5vh;
  padding: 10px;
  font-size: 0.85em;
  overflow-y: scroll;
}

.order-detail-wrap h3 {
  margin: 0 0 0.25em;
  border-bottom: 1px solid silver;
}

.order-detail-wrap .row {
  padding-bottom: 15px;
}

.detail-tbl tfoot td {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: right;
}

.rgEditForm .rgHeader {
  height: auto !important;
  padding-bottom: 8px;
  overflow: hidden;
}

.button-search {
  display: block;
  width: 14px;
  height: 14px;
  background-image: url("/images/search-14.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
}

.button-save {
  display: block;
  width: 24px;
  height: 24px;
}

.button-save img {
  width: 24px;
}

.account-table {
  width: 100%;
  font-size: 12px;
  text-align: left;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-radius: 3px;
}

.account-table thead th {
  padding: 5px;
  line-height: 1;
  background-color: #ededed;
  white-space: nowrap;
}

.account-table tbody td,
.account-table tfoot td {
  padding: 5px;
  border-top: 1px solid #ddd;
}

.account-table .button-search {
  margin: 0 auto;
}

.account-table .button-save {
  margin: 0 auto;
}

.account-table .button-save img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.account-table__track a {
  color: inherit;
  text-decoration: underline;
}

.account-table--currency {
  text-align: right;
}

.account-table--center {
  text-align: center;
}

.order-history-table .rgMasterTable {
  font-size: 11px !important;
  line-height: 1.25 !important;
}

.order-history-table .rgEditForm {
  z-index: 9000 !important;
}

.order-history-table .icon-pdf {
  display: inline-block;
  max-width: 24px;
  height: auto;
}

.order-history-table .account-table {
  font-size: 11px;
}

.order-history-table .account-table thead th {
  white-space: normal;
}

.order-history-table .RadGrid_Silk {
  border: none;
}

.order-history-table .RadGrid_Silk .rgHeader {
  font-weight: bold;
  text-align: left !important;
}

.order-history__track a {
  color: inherit;
  text-decoration: underline;
}

.order-history__no-items {
  padding: 0 10px;
}

.bc-orders__header {
  margin: 0;
  padding: 0 0 5px;
  font-size: 16px;
}

.bc-orders__section {
  margin-bottom: 20px;
}

@media only screen and (min-width: 840px) {
  .bc-orders {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .bc-orders__section {
    flex: 1 1 50%;
    padding-left: 10px;
  }
  .bc-orders__section:first-child {
    padding-right: 10px;
    padding-left: 0;
  }
  .bc-orders__header {
    display: none;
  }
}

.bc-orders .selected td {
  font-weight: bold;
  background-color: #ffffe0;
}

@media only screen and (min-width: 680px) {
  .account {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .account__sidebar {
    flex: 1;
    padding: 10px;
  }
  .account__main {
    flex: 3;
    padding: 10px;
  }
}

.account-nav {
  padding: 10px 0;
}

.account-nav__list {
  border-top: 5px solid black;
}

.account-nav__link {
  display: block;
  padding: 4px 8px;
  color: black;
}

.account-nav__link:hover {
  background-color: #eeeeee;
}

.account-nav__link--selected, .account-nav__link--selected:hover {
  font-weight: bold;
  color: black;
  text-decoration: none;
  background-color: #d6d6d6;
}

@media only screen and (max-width: 680px) {
  .account-nav__list {
    padding-bottom: 20px;
    border-top: none;
    overflow: hidden;
  }
  .account-nav__list > li {
    float: left;
    width: 33%;
    text-align: center;
  }
  .account-nav__link {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 2px;
  }
}

.account-blocked {
  width: 98%;
  margin: 0 auto;
  color: #d32121;
  font-weight: bold;
}

.account-panel {
  padding: 0 0 20px;
}

.account-panel--error {
  padding: 0 10px 10px;
}

.account-panel--change {
  padding: 0 10px 20px;
}

.account-panel__header {
  margin: 0 0 0.5em;
  padding: 0 0 0.25em;
  font-size: 1.25em;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.account-panel__action {
  margin: 0;
  padding: 0 0 20px;
}

.address-new {
  font-size: 0.9em;
  font-weight: normal;
}

.account-panel .input--full {
  margin-bottom: 5px;
}

.address-wrap {
  padding: 0 20px;
}

@media only screen and (min-width: 680px) {
  .address-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .address-wrap .account-panel {
    width: calc(50% - 10px);
  }
  .address-wrap .account-panel--error {
    width: 100%;
  }
}

.dashboard__input-required .popover__content {
  max-width: 350px;
  padding: 15px;
  text-align: left;
}

.dashboard__input-required .popover__content p {
  margin: 0 0 15px 0;
  padding: 0;
}

.dashboard__input-required .lbl--error {
  display: block;
  margin-bottom: 15px;
}

.single-input {
  display: flex;
  flex-direction: row;
}

.single-input .input--full {
  flex: 1;
}

.single-input .button {
  flex: 0 auto;
  border-radius: 0;
}

.dashboard-section {
  margin: 50px 5px 0;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.dashboard-banners .standard-banner {
  margin-top: 0px;
  margin-bottom: 10px;
}

.dashboard-banners .standard-banner__button {
  display: none;
}

.dashboard-banners .standard-banner__title {
  display: none;
}

.dashboard-list {
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
}

.dashboard__header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin: 10px 0 15px;
  padding: 0 20px;
}

.dashboard__header input {
  margin-top: 10px;
}

.dashboard__title {
  margin: 0;
  grid-column: 1 / span 3;
  justify-self: center;
}

.dashboard-list__item {
  position: relative;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 10px;
  align-items: start;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.dashboard-list__item:last-of-type {
  border-bottom: none;
}

.dashboard__no-items {
  padding: 0 10px;
}

.dashboard__no-items a {
  text-decoration: underline;
}

.dashboard-list__add-qty {
  text-align: center;
  grid-row: 2;
  display: flex;
  align-items: center;
  column-gap: 2px;
}

.dashboard-list__link {
  color: inherit;
}

.dashboard-list__name {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
}

.dashboard-list__size {
  font-size: 0.8em;
  white-space: nowrap;
}

.dashboard-list__sku,
.dashboard-sample__date {
  font-size: 11px;
}

.dashboard-list__price {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  min-width: 65px;
}

.dashboard-list__price .price__current {
  font-size: 1.5em;
}

.dashboard-list__price .price__label,
.dashboard-list__price .price__label--salon,
.dashboard-list__price .price__label--sale,
.dashboard-list__price .price__label--savings {
  font-size: 0.75em;
  font-weight: normal;
}

.dashboard-list__stock,
.dashboard-list__contract {
  grid-row: 2;
}

.dashboard-list__contract {
  font-weight: normal;
}

.dashboard-list__photo {
  display: block;
  max-width: 75px;
  height: auto;
  margin: 0 auto;
}

.dashboard-sample__title {
  font-weight: bold;
  text-transform: uppercase;
}

.dashboard__disclaimer {
  font-size: 0.9em;
  margin-top: 8px;
  grid-column: 1 / span 3;
  text-align: center;
}

.dashboard-list__purchase {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr auto auto;
  justify-items: end;
  margin-top: 0;
  row-gap: 5px;
}

.dashboard-list .dashboard-list__purchase {
  align-items: center;
}

.dashboard__footer {
  padding-top: 10px;
  text-align: center;
}

.dashboard__footer .button {
  margin-bottom: 5px;
}

.dashboard__add-all {
  grid-column: 2;
  justify-self: center;
}

.dashboard__error {
  display: block;
  padding-bottom: 5px;
  text-align: center;
}

.dashboard__next-link {
  display: inline-block;
  padding-right: 5px;
  margin-left: auto;
}

.dashboard__next-link::after {
  content: " >";
}

.dashboard .button--small {
  border-radius: 0px;
  border: none;
}

@media only screen and (min-width: 480.02px) {
  .dashboard__header input {
    margin-top: 5px;
  }
  .dashboard__add-all {
    grid-column: 3;
    justify-self: end;
  }
  .dashboard-list__item {
    grid-gap: 10px;
  }
  .dashboard__footer {
    text-align: right;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 820px) {
  .dashboard__title {
    grid-column: 2;
  }
  .dashboard-sample-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
  .dashboard-list__item {
    grid-template-columns: 75px 3fr 1fr;
    /*align-items: center;*/
    width: 50%;
    border-bottom: none;
  }
  .dashboard-list__photo {
    max-width: 100%;
  }
  .dashboard-list__purchase {
    grid-template-columns: auto auto;
    /*justify-items: start;*/
    justify-self: end;
    /*column-gap: 10px;*/
    /*margin-top: 10px;*/
  }
  .dashboard-list__purchase .input--qty {
    justify-self: center;
  }
}

@media only screen and (min-width: 1050px) {
  .dashboard-section {
    margin: 50px 0 0;
  }
}

.cart-error {
  display: block;
  padding: 5px 10px;
  font-weight: bold;
}

.cart-tbl {
  width: 100%;
  margin: 15px 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.cart-tbl .cart-tbl__check {
  max-width: 70px;
  padding: 10px 0;
}

.cart-tbl caption {
  padding: 0 10px 5px;
  text-align: left;
  overflow: hidden;
}

.cart-tbl .button--checkout {
  float: right;
  margin: 0 0 0 5px;
}

.cart-tbl .button--continueshopping {
  float: left;
}

.cart-tbl .button--updatecart {
  float: right;
  margin-right: 6px;
}

.cart-tbl thead {
  clear: both;
}

.cart-tbl th {
  padding: 10px;
  text-align: left;
  background-color: #d6d6d6;
}

.cart-tbl th .button--line {
  margin-right: 10px;
}

/* .cart-tbl .alternate td { */
.cart-tbl tr:nth-child(even) td {
  background-color: #eeeeee;
}

.cart-tbl tr.tr--footer td {
  background-color: transparent;
}

.cart-tbl td {
  padding: 10px;
  text-align: left;
}

.cart-tbl .title {
  padding: 0;
  overflow: auto;
}

.cart-tbl .nopadding {
  padding: 0;
  text-align: left;
}

.cart-tbl .center {
  text-align: center;
  padding: 0 20px;
}

.cart-available {
  margin: 5px 0 0;
}

.cart-update {
  display: block;
  margin: 5px 0 0;
}

.cart-desc {
  padding: 0 0 10px;
}

.cart-tbl .cart-save {
  padding: 0 10px;
  color: #d32121;
  font-style: italic;
  font-weight: normal;
  text-align: right;
}

.cart-tbl .cart-total {
  font-size: 1.4em;
  font-weight: bold;
}

.cart-sku {
  font-size: 0.8em;
}

.cart-tbl .name {
  max-width: 480px;
}

.header--cart {
  float: left;
}

.current-ship p {
  margin: 0;
  padding: 0;
  text-align: right;
}

.add-from-list {
  float: right;
  padding-top: 10px;
}

.add-from-section {
  float: left;
  padding-left: 4px;
}

.quick-order .add-from-list {
  padding-top: 17px;
}

.pricesmallstrike {
  color: #999999;
  font-size: 0.8em;
  font-weight: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 680px) {
  .add-from-list {
    float: none;
    clear: both;
    padding: 10px;
  }
  .add-from-section {
    float: none;
    width: 100%;
    padding: 0 0 5px;
  }
}

@media only screen and (max-width: 480px) {
  .cart-tbl th {
    display: none;
  }
  .cart-tbl tbody tr {
    display: block;
    overflow: hidden;
  }
  .cart-tbl td {
    display: block;
  }
  .cart-tbl .cart-sm {
    float: left;
    width: 33.3333333%;
    padding: 10px;
  }
  .cart-tbl .alternate {
    background-color: #eeeeee;
  }
  .yourprice {
    display: block;
  }
}

@media only screen and (max-width: 420px) {
  .add-from-section select, .add-from-section .button {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  .add-from-section .button {
    text-align: center;
  }
}

.txt--left {
  text-align: left;
}

.txt--center {
  text-align: center;
}

.txt--right {
  text-align: right;
}

.button--refresh {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/refresh.png") no-repeat 0 0;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  border: none;
  line-height: 1;
  font-size: 0;
  opacity: 0.6;
  transition: 0.3s opacity;
}

.button--refresh:hover {
  opacity: 0.9;
}

.cart__outer {
  overflow: hidden;
}

.cart-outer .overlay {
  position: fixed;
}

.cart-outer .spinner-panel {
  top: 0;
  bottom: 0;
}

.cart__table-inner {
  padding-bottom: 15px;
}

cart__table-inner-split {
  padding-bottom: 15px;
}

.cart__table {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.cart__th {
  display: none;
}

.cart__row {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.cart__row--repbrand {
  background-color: #eee;
}

.cart__row--repbrand_error {
  background-color: lightpink;
}

.cart__cell {
  flex: 1 33.3333333%;
  float: left;
  width: 33.33333%;
  padding: 10px;
}

.cart__empty {
  padding: 10px;
}

.cart__cell--description {
  float: none;
  flex: 3 0 100%;
  width: 100%;
}

.cart__row:nth-child(odd), .sample-placeholder:nth-child(odd) {
  background-color: #eee;
}

.cart__caption {
  padding: 10px 10px 20px;
  overflow: hidden;
}

.cart__caption--bottom {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.cart__caption-right, .cart__caption-left {
  padding: 5px 0;
}

.cart__caption-right .button, .cart__caption-left .button {
  display: block;
}

.cart__overview-countdown {
  padding-top: 5px;
  font-size: 16px;
  text-align: center;
}

.cart__requested-qty {
  font-size: 0.85em;
  white-space: nowrap;
}

@media only screen and (min-width: 480px) {
  .cart__caption {
    margin: 0 -10px;
  }
  .cart__caption-right, .cart__caption--bottom .cart__caption-full {
    float: right;
    width: 50%;
    padding: 0 5px;
  }
  .cart__caption-left {
    float: left;
    width: 50%;
    padding: 0 5px;
  }
}

@media only screen and (min-width: 540px) {
  .cart__right {
    overflow: hidden;
  }
  .cart__overview {
    float: left;
    width: 50%;
    margin-top: 10px;
    overflow: hidden;
  }
  .cart__actions {
    float: right;
    width: 50%;
  }
  .cart__table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-top: none;
    border-bottom: none;
  }
  .cart__th {
    display: table-cell;
    padding: 5px 10px;
    background: #fff;
    border-bottom: 1px solid #ededed;
  }
  .cart__foot {
    display: table-cell;
    padding: 5px 10px;
    background: #fff;
    border-top: 1px solid #ededed;
  }
  .cart__row {
    display: table-row;
  }
  .cart__cell {
    float: none;
    display: table-cell;
    width: auto;
  }
  .cart__row:nth-child(odd), .sample-placeholder:nth-child(odd) {
    background-color: transparent;
  }
  .cart__row:nth-child(odd) .cart__cell, .sample-placeholder:nth-child(odd) td {
    background-color: #eee;
  }
}

@media only screen and (min-width: 640px) {
  .cart__caption-right, .cart__caption-left {
    width: auto;
    padding: 5px 10px;
  }
  .cart__caption-right .button, .cart__caption-left .button {
    display: inline-block;
  }
}

@media only screen and (min-width: 820px) {
  .cart__actions, .cart__overview {
    float: none;
    width: 100%;
  }
  .cart__right {
    float: right;
    width: 250px;
    padding-left: 10px;
  }
  .cart__left {
    overflow: hidden;
  }
  .cart__table-inner {
    max-height: 400px;
    overflow-y: auto;
  }
  .cart__table-wrap {
    position: relative;
  }
  .cart__table-wrap::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    pointer-events: none;
  }
  .cart__caption--bottom .cart__caption-full {
    display: none;
  }
}

.cart__overview {
  padding: 10px;
  background: #ededed;
}

.cart__overview-item {
  padding: 5px 0;
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
  overflow: hidden;
}

.cart__overview-item--savings {
  font-size: 0.9em;
}

.cart__overview-label {
  float: left;
}

.cart__overview-value {
  float: right;
}

.cart__overview-item:first-child {
  padding-top: 0;
}

.cart__overview-checkout {
  margin-top: 15px;
}

.also-like, .recently-viewed {
  margin-top: 15px;
  padding: 10px;
}

.also-like__wrap {
  position: relative;
}

.also-like__wrap::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 15px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.also-like__inner {
  max-width: 100%;
  overflow-x: auto;
}

.also-like__header, .recently-viewed__header {
  font-size: 1.25em;
  text-align: center;
  text-transform: capitalize;
}

.also-like--product {
  margin-top: 45px;
}

.also-like--product .also-like__header {
  font-size: 1.75em;
}

.recently-viewed.recently-viewed--product {
  margin-top: 30px;
}

.recently-viewed.recently-viewed--product .recently-viewed__header {
  font-size: 1.75em;
}

.prod-list--vertical {
  margin: 0;
  padding: 0;
}

.cart-update {
  white-space: nowrap;
}

.related-add {
  text-align: center;
}

.related-add__button {
  display: inline-block;
}

.also-like .prod-list, .recently-viewed .prod-list {
  justify-content: center;
}

.also-like .prod-list__header, .recently-viewed .prod-list__header {
  font-size: 14px;
}

.also-like .button, .recently-viewed .button {
  font-size: 12px;
  text-transform: none;
}

.also-like .prod-list__item, .recently-viewed .prod-list__item {
  width: 50%;
}

.also-like .prod-list__item:nth-child(2n+1), .recently-viewed .prod-list__item:nth-child(2n+1) {
  clear: both;
}

.also-like .input--qty, .recently-viewed .input--qty {
  margin-bottom: 5px;
}

@media only screen and (min-width: 480px) {
  .also-like .prod-list__item, .recently-viewed .prod-list__item {
    width: 33.33333333%;
  }
  .also-like .prod-list__item:nth-child(2n+1), .recently-viewed .prod-list__item:nth-child(2n+1) {
    clear: none;
  }
  .also-like .prod-list__item:nth-child(3n+1), .recently-viewed .prod-list__item:nth-child(3n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 680px) {
  .also-like .prod-list__item, .recently-viewed .prod-list__item {
    width: 25%;
  }
  .also-like .prod-list__item:nth-child(3n+1), .recently-viewed .prod-list__item:nth-child(3n+1) {
    clear: none;
  }
  .also-like .prod-list__item:nth-child(4n+1), .recently-viewed .prod-list__item:nth-child(4n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 900px) {
  .also-like .prod-list__item, .recently-viewed .prod-list__item {
    width: 20%;
  }
  .also-like .prod-list__item:nth-child(4n+1), .recently-viewed .prod-list__item:nth-child(4n+1) {
    clear: none;
  }
  .also-like .prod-list__item:nth-child(5n+1), .recently-viewed .prod-list__item:nth-child(5n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 680px) {
  .also-like--product .prod-list__item, .recently-viewed--product .prod-list__item {
    width: 25%;
  }
  .also-like--product .prod-list__item:nth-child(3n+1), .recently-viewed--product .prod-list__item:nth-child(3n+1) {
    clear: none;
  }
  .also-like--product .prod-list__item:nth-child(4n+1), .recently-viewed--product .prod-list__item:nth-child(4n+1) {
    clear: none;
  }
}

@media only screen and (min-width: 900px) {
  .also-like--product .prod-list__item:nth-child(4n+1), .recently-viewed--product .prod-list__item:nth-child(4n+1) {
    clear: none;
  }
  .also-like--product .prod-list__item:nth-child(5n+1), .recently-viewed--product .prod-list__item:nth-child(5n+1) {
    clear: none;
  }
}

.payment-options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.payment-options li {
  margin: 5px 0;
}

.payment-options li label {
  display: inline-block;
}

.multi-select--radio {
  margin: 0 0 15px;
}

.check-inventory .cart__row:not([data-apiqty=""]) .cart__cell {
  color: #fff;
  background-color: #c12428;
}

.check-inventory .cart__row:not([data-apiqty=""]) .cart__cell .list-link, .check-inventory .cart__row:not([data-apiqty=""]) .cart__cell .cart-update {
  color: #fff;
}

.list-tbl {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.list-tbl tr td {
  border-bottom: 1px solid silver;
}

.list-tbl tr:nth-child(even) td {
  background-color: #eeeeee;
  border-bottom: none;
}

.list-tbl tr:nth-child(odd) td {
  border-bottom: none;
}

.list-tbl td {
  padding: 5px;
}

.tbl-list__cart {
  padding: 5px;
}

.tbl-list__col {
  display: inline-block;
  margin: 0 0 0 5px;
  vertical-align: middle;
}

.list-sku {
  padding: 2px 0 0;
  margin: 0;
  font-size: 0.8em;
}

.tbl-list__actions {
  max-width: 110px;
}

.modify-list {
  margin: 0 0 10px;
}

.list-select {
  padding: 10px 10px 0;
}

.list-actions {
  padding: 10px 5px;
}

.list-actions-right {
  float: right;
}

.list-add-error {
  margin: 0;
  padding: 0 0 0.5em 5px;
}

@media only screen and (max-width: 840px) {
  .tbl-list__actions .button {
    display: block;
    text-align: center;
    margin: 0 0 5px;
  }
}

.home__text {
  margin: 15px;
  text-align: center;
}

.home__text img {
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

.main-banner-wrap .bx-controls-direction {
  display: none;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 10px;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 10px;
}

.banner-feature img {
  display: block;
  width: 100%;
  height: auto;
}

.banner-feature a {
  display: inline-block;
}

.banner-feature .banner-feature--first {
  display: block;
}

.bar {
  padding: 8px;
  color: white;
  font-weight: bold;
  background-color: #333333;
  overflow: auto;
}

.bar a {
  color: white;
  font-size: 0.8em;
}

.slidewrap img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}

.slidewrap a {
  color: #333333;
}

.slider-outer {
  overflow: hidden;
}

.slider-caption {
  margin: 8px 0 0;
  padding: 0 5px;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
}

.slidewrap .slide-hidden {
  opacity: 0;
}

.post .bx-pager-item:first-of-type:last-of-type {
  display: none;
}

.slider--featured .bx-viewport .slidewrap {
  overflow-y: visible;
}

.slider--featured .bx-viewport .slidewrap a {
  display: block;
  text-align: center;
  text-decoration: none;
}

.slider--featured .bx-viewport .slidewrap a::after {
  content: 'Shop Now';
  position: relative;
  top: -0.5em;
  display: inline-block;
  margin: 0 auto;
  padding: 0.5em 1em;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0;
  background-color: #fff;
  border: 2px solid #000;
  transition: opacity 0.4s, transform 0.4s;
  transform: translateY(0);
  white-space: nowrap;
}

.slider--featured .bx-viewport .slidewrap a .slider-caption {
  transition: opacity 0.3s;
}

.slider--featured .bx-viewport .slidewrap a:hover::after {
  opacity: 1;
  text-decoration: none;
  transform: translateY(-0.5em);
}

.slider--featured .bx-viewport .slidewrap a:hover .slider-caption {
  opacity: 0;
  text-decoration: none;
}

.slider--featured .bx-wrapper {
  margin-bottom: 15px;
}

@media only screen and (min-width: 480px) {
  .slider--featured .bx-viewport .slidewrap a::after {
    font-size: 18px;
  }
}

.header--home,
.home-articles__header {
  position: relative;
}

.home-articles {
  overflow: hidden;
}

.home-articles__list {
  margin: 0 -15px 20px;
}

.home-articles__item {
  float: left;
  width: 33.3333333333%;
  margin-top: 30px;
  padding: 0 15px;
}

.home-articles__item:nth-child(3n+1) {
  clear: both;
}

.home-article__img {
  margin-bottom: 10px;
}

.home-article__img img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.home-article__title {
  margin: 0;
  padding: 0;
  font-size: 1.25em;
  text-transform: uppercase;
}

.home-article__link {
  display: block;
}

.home-article__link--read {
  text-transform: uppercase;
}

.home-article__link--read::after {
  content: ' >';
}

.home-article__link:hover {
  text-decoration: none;
}

.home-article__link:hover .home-article__title {
  text-decoration: underline;
}

.home-article__more {
  display: none;
  visibility: hidden;
}

.home-article__text p {
  margin: 3px 0 0;
  padding: 0;
  color: #6d6e71;
  font-size: 11px;
}

.home-article__text {
  position: relative;
}

.home-article__teaser {
  color: inherit;
}

.home-articles__more {
  clear: both;
  display: block;
  text-align: right;
  text-transform: uppercase;
  background: #6d6e71;
}

.home-articles__more:hover span {
  text-decoration: underline;
}

.home-articles__more span {
  display: inline-block;
  padding: 2px 5px 2px 10px;
  background: white;
}

.home-articles__more span::after {
  content: ' >';
}

@media only screen and (max-width: 1045px) {
  .home-articles__list {
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 680px) {
  .home-articles__item {
    width: 50%;
  }
  .home-articles__item:nth-child(3n+1) {
    clear: none;
  }
  .home-articles__item:nth-child(2n+1) {
    clear: both;
  }
}

@media only screen and (max-width: 420px) {
  .home-articles__list {
    margin-left: 0;
    margin-right: 0;
  }
  .home-articles__item {
    float: none;
    width: 100%;
  }
  .home-article__img img {
    width: 100%;
  }
  .home-article__text {
    margin: 0;
  }
}

.standard-banner {
  max-width: 1400px;
  margin: 30px auto;
}

.standard-banner__link {
  display: block;
}

.standard-banner__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.home-articles__list--fb {
  float: left;
  width: 66.666666%;
  padding-right: 30px;
}

.home-articles__list--fb .home-articles__item {
  width: 50%;
}

.home-articles__list--fb .home-articles__item:nth-child(3n+1) {
  clear: none;
}

.home-articles__list--fb .home-articles__item:nth-child(2n+1) {
  clear: both;
}

.fb-feed {
  float: right;
  width: 33.333333333%;
}

@media only screen and (max-width: 600px) {
  .fb-feed {
    width: 50%;
  }
  .home-articles__list--fb {
    width: 50%;
  }
  .home-articles__list--fb .home-articles__item {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .fb-feed {
    float: none;
    width: 100%;
    margin-bottom: 30px;
  }
  .home-articles__list--fb {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
  }
}

.fader {
  position: relative;
  z-index: 10;
}

.fader__spacer {
  width: 100%;
  padding-bottom: 100%;
}

.fader__list > li {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fader__item {
  z-index: 100;
  opacity: 0;
  transition: opacity 2s;
}

.fader__item--active {
  z-index: 200;
  opacity: 1;
  transition: opacity 1s;
}

.fader__title {
  margin: 0;
  padding: 5px;
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: black;
}

.fader img {
  display: block;
  width: 100%;
  height: auto;
}

.header-message {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: #7e7e7e;
}

.blog__title {
  font-size: 2.75em;
  margin-bottom: 0.75em;
}

.post {
  padding: 20px 0 0;
}

.post__header {
  margin: 0 0 5px;
  text-align: center;
}

.post__subhead {
  margin: 2em 0 0.25em;
  padding: 0 0 0.5em;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: 1px solid #d1d1d1;
}

.post__subhead:first-child {
  margin-top: 0;
}

.post__link {
  margin: 0;
  padding: 0;
}

.post__title {
  margin: 0;
  padding: 0;
}

.post__teaser, .post__body {
  line-height: 1.4;
}

.post__teaser {
  font-style: italic;
}

.post__body ol, .post__body ul {
  overflow: hidden;
}

.post__text {
  position: relative;
  margin: 0 0 30px;
  padding: 0 0 20px;
  border-bottom: 1px solid #d1d1d1;
}

.post__text:last-of-type {
  border-bottom: none;
}

.post__primary {
  position: relative;
  float: left;
  width: 75%;
  padding: 0 25px;
}

.post__secondary {
  position: relative;
  float: right;
  width: 25%;
}

.post__tertiary {
  float: right;
  clear: right;
  width: 25%;
  padding-top: 10px;
}

.post__more {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.post__more::after {
  content: ' \00bb';
}

.post__img, .post__teaser, .post__more, .post__body {
  display: block;
  margin: 0 0 10px;
}

.post__teaser--logged-out {
  position: relative;
  width: 85%;
  margin: 20px auto 0;
  padding: 10px 0 30px;
  text-align: center;
}

.post__teaser--logged-out::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 60%;
  margin: auto;
  height: 1px;
  border-top: 1px solid #ddd;
}

.post__img img, .post__body img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.post__text--list .post__header {
  margin-bottom: 10px;
  text-align: left;
}

.post__text--list .post__img {
  float: left;
  width: 33.3333333%;
  padding: 0 15px 15px 0;
}

@media only screen and (max-width: 640px) {
  .post__primary {
    float: none;
    width: 100%;
    left: auto;
    right: auto;
    padding: 0 10px;
  }
  .post__secondary, .post__tertiary {
    clear: none;
    float: none;
    width: 100%;
    padding: 40px 60px 0;
  }
  .post__tertiary {
    padding-bottom: 20px;
  }
  .post__text--list .post__img {
    float: none;
    width: 100%;
  }
  .post__more {
    position: static;
    text-align: right;
  }
}

@media only screen and (max-width: 480px) {
  .post__secondary, .post__tertiary {
    padding: 40px 20px 0;
  }
  .post__tertiary {
    padding-bottom: 20px;
  }
}

.cloud {
  margin: 15px 0;
  padding: 10px 5px;
  line-height: 1.8em;
  text-align: center;
  border-style: solid;
  border-color: #d1d1d1;
  border-width: 1px 0;
}

.cloud__list > li {
  display: inline;
}

.cloud__tag--xs {
  font-size: 0.9em;
}

.cloud__tag--s {
  font-size: 1.1em;
}

.cloud__tag--m {
  font-size: 1.3em;
}

.cloud__tag--l {
  font-size: 1.5em;
}

.cloud__tag--xl {
  font-size: 1.7em;
}

.gallery {
  margin: 0 -5px 15px;
}

.gallery__item {
  float: left;
  width: 33.33333333%;
  padding: 0 5px 10px;
}

.gallery__item > img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.gallery__categories {
  margin: 15px 0 5px;
}

.gallery__categories a {
  font-weight: bold;
}

.gallery__categories > li {
  margin-right: 4px;
}

.gallery__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay__content {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 150px;
  min-height: 150px;
  background: black;
  transform: translateY(-50%) translateX(-50%);
}

.gallery__image img {
  display: block;
  max-height: 70vh;
  max-width: 90vw;
  margin: 0 auto;
}

.overlay__next, .overlay__prev, .overlay__close {
  display: block;
  width: 2em;
  height: 2em;
  color: #efefef;
  font-size: 1.5em;
  line-height: 2em;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}

.overlay__next:hover, .overlay__prev:hover, .overlay__close:hover {
  text-decoration: none;
}

.overlay__next {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
}

.overlay__prev {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
}

.overlay__close {
  position: absolute;
  top: 0;
  right: 0;
}

.gallery__share {
  position: absolute;
  bottom: -15px;
  margin: 0 0 10px 10px;
  opacity: 0;
  transition: opacity 0.3s, bottom 0.3s;
}

.overlay__content:hover .gallery__share {
  bottom: 0;
  opacity: 1;
  transition: opacity 0.3s, bottom 0.3s;
}

@media only screen and (max-width: 540px) {
  .gallery__item {
    width: 50%;
    padding: 0 2px 2px;
  }
  .gallery__image img {
    max-width: 90vw;
    max-height: 90vh;
  }
}

.saved-cards {
  margin: 15px 0 0;
  padding: 10px;
  background: #fbfbfb;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.saved-cards__header {
  margin: 0;
  padding: 0 0 0.75em;
  font-size: 1.15em;
}

.saved-cards__list {
  max-height: 235px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.saved-cards__note {
  margin: 0;
  padding: 0 0 0.5em;
  color: #6b6b6b;
  font-size: 0.8em;
  font-style: italic;
}

.saved-cards__list > li {
  margin: 0 0 10px;
}

.saved-cards input[type="radio"] {
  margin: 0 3px 0 0;
  vertical-align: middle;
}

.popover__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background: rgba(0, 0, 0, 0.5);
}

.popover__content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9001;
  width: 80%;
  max-width: 500px;
  max-height: 95vh;
  overflow-y: auto;
  text-align: center;
  background: transparent;
  transform: translate(-50%, -50%);
}

.popover__close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  width: 25px;
  height: 25px;
  background-image: url("/images/cache/close_icon.jpg");
  border: none;
  cursor: pointer;
}

.popover__link {
  display: block;
  color: inherit;
}

.popover__link--main {
  margin: 10px 0 0;
  font-size: 16px;
}

.popover__link--main:first-child {
  margin-top: 0;
}

.popover__text {
  padding: 10px 0;
  background-color: #fff;
  margin-top: -1px;
}

.popover__text.is--png {
  background: transparent;
}

.popover img {
  display: block;
  width: 100%;
  height: auto;
}

.popover h2 {
  margin: 0 0 10px;
  padding: 0;
  color: #959595;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
}

.popover p {
  padding: 0 25px;
  font-size: 14px;
}

.email-signup {
  display: none;
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 8000;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding: 15px;
  color: #959595;
  background-color: #fff;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
}

.email-signup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  text-transform: uppercase;
}

.email-signup__title {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 1.15em;
  font-weight: normal;
  text-transform: uppercase;
}

.email-signup label {
  display: block;
  margin-bottom: 3px;
  font-weight: bold;
}

.samples {
  margin: 30px 0;
}

.samples__toggle--container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.samples__toggle--container .samples__toggle--svg-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  padding: 12px;
}

.samples__toggle--container .samples__toggle--svg-container svg {
  transition: all 0.2s ease;
  transform: rotate(180deg);
}

.samples__toggle {
  display: flex;
  align-items: center;
  /*z-index: 400;*/
  width: 100%;
  max-width: 400px;
  padding: 0 15px;
  padding-right: 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  background: #1d1d1d;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  /*removed 1/11/22 - was causing white line across page*/
  /*.samples--prod-visible &::after {
		content: "";
		position: absolute;
		right: 0;
		bottom: -5px;
		left: 0;
		width: 100%;
		height: 10px;
		background: #fefefe;
		pointer-events: none;
		opacity: 1;
	}*/
}

.samples__toggle p, .samples__toggle img {
  margin: 0 6px;
}

.samples__toggle p {
  margin-right: 15px;
}

.samples--prod-visible .samples__toggle--svg-container svg {
  transform: rotate(0deg);
}

.samples__inner {
  display: none;
  position: relative;
  top: 1px;
  /*z-index: 300;*/
  max-width: none;
  padding: 0;
  background: #fefefe;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.samples--prod-visible .samples__inner {
  display: block;
}

.sample {
  position: relative;
  padding: 15px;
}

.sample::after {
  content: '';
  width: 70%;
  height: 1px;
  background: linear-gradient(to right, rgba(238, 238, 238, 0) 0%, #eeeeee 10%, #eeeeee 90%, rgba(238, 238, 238, 0) 100%);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.sample:last-child::after {
  display: none;
}

.sample__title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.25;
  color: #d32121;
}

.sample__description {
  margin: 5px 0 15px 0;
  padding: 0;
}

.sample__inner {
  overflow-x: hidden;
}

.sample__add-more {
  display: block;
  color: #d81f1f;
  font-style: italic;
  font-weight: bold;
}

.sample__auto-add-message {
  display: block;
  color: limegreen;
  font-style: italic;
  font-weight: bold;
}

.sample__products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.sample-product {
  width: calc(100% / 6);
  padding: 5px 10px;
  text-align: center;
}

.sample-product .list-cart {
  text-align: center;
}

.prod-info .sample-product {
  width: 20%;
}

@media only screen and (max-width: 480px) {
  .sample-product,
  .prod-info .sample-product {
    width: 33.33%;
  }
}

.sample-product__image {
  display: block;
  max-width: 75px;
  height: auto;
  margin: 0 auto;
}

.sample-product__name {
  margin: 0 0 5px;
  padding: 0;
  font-size: 12px;
}

.cart-table {
  width: 100%;
  margin: 15px 0 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.cart-table__actions--right {
  float: right;
  margin: 5px 10px;
}

.cart-table__actions--left {
  float: left;
  margin: 5px 10px;
}

.cart-table__total {
  font-size: 1.4em;
  font-weight: bold;
}

.cart-table__sku {
  font-size: 0.8em;
}

.cart-table__desc {
  padding: 0 0 10px;
}

.cart-table__update {
  display: block;
  margin: 5px 0 0;
}

.cart-table__name {
  max-width: 480px;
}

.cart-table td, .cart-table th {
  padding: 10px;
}

.cart-table__header th {
  text-align: left;
  background-color: #d6d6d6;
}

.cart-table .cart-table__savings {
  padding-top: 0;
  color: #d32121;
  font-style: italic;
}

.cart-table__body tr:nth-child(even) td {
  background-color: #eeeeee;
}

.cart-table__footer tr:first-child td {
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.home-blog-categories {
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

.home-blog-category {
  margin: 15px 0;
}

.home-blog-category__header {
  position: relative;
  min-height: 80px;
  padding: 15px 10px 5px;
  background: #676767;
  color: white;
  overflow: hidden;
}

.home-blog-category__image {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.home-blog-category__content {
  padding: 0 10px;
  line-height: 1.5;
}

.home-blog-category__link {
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}

.home-blog-category__link::after {
  content: ' >';
}

.home-blog-category__header > h2 {
  position: absolute;
  bottom: 5px;
  left: 10px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-transform: uppercase;
}

@media only screen and (min-width: 480px) {
  .home-blog-categories__inner {
    margin: 0 -5px;
  }
  .home-blog-category {
    float: left;
    width: 50%;
    padding: 0 5px;
  }
  .home-blog-category:nth-child(2n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 780px) {
  .home-blog-category {
    width: 25%;
  }
  .home-blog-category__header {
    min-height: 125px;
  }
  .home-blog-category:nth-child(2n+1) {
    clear: none;
  }
  .home-blog-category:nth-child(4n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 1000px) {
  .home-blog-category__content {
    padding: 0;
  }
}

.pencil,
.pencil-multi {
  padding-top: 0;
}

.pencil-banner {
  display: block;
  margin: 0 auto;
}

.pencil-banner__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.pencil a {
  color: inherit;
}

.pencil > div:not(.pencil-banner) {
  text-align: center;
  padding: 12px 10px;
}

.pencil-text a {
  display: inline-block;
}

.pencil-multi .pencil-text {
  display: none;
}

.pencil-multi .pencil-text--active {
  display: block;
}

.page--explore-deals-default .main-content {
  max-width: none;
}

.page--explore-deals-default .breadcrumb {
  display: none;
}

.explore-deals-wrap > div {
  max-width: 1000px;
  margin: 60px auto 0;
}

.explore-deals-wrap p {
  font-size: 1.1em;
}

.explore-deals-wrap h1.bar,
.explore-deals-wrap .promo-header p {
  padding-bottom: 10px;
}

.explore-deals-wrap h1.bar,
.explore-deals-wrap h2.bar {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.explore-deals-wrap .bar span:before {
  content: none;
}

.explore-deals-wrap .product-list-content {
  margin-top: 40px;
  margin-bottom: 20px;
}

.explore-deals-wrap .product-list__footer {
  text-align: center;
}

.explore-deals-wrap .home-article__text .home-article__link:after {
  content: none;
}

.explore-deals-wrap .promo-banners--threecol {
  max-width: 1500px;
  margin-top: 0px;
}

.explore-deals-wrap .promo-header {
  max-width: none;
  margin: 0px auto;
}

.promo-header {
  width: 100%;
  background-color: #F8F5F0;
  min-height: 150px;
  padding: 20px;
  text-align: center;
}

.promo-brands-wrap {
  margin-bottom: 20px;
  text-align: center;
}

.promo-brands {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.promo-brand {
  margin-top: 20px;
  width: 33.333%;
  display: flex;
}

.promo-brand__image {
  max-width: 70%;
}

.promo-brand__text {
  padding-top: 0px;
  font-size: 16px;
  color: #58595b;
}

.promo-brand__link:hover {
  color: inherit;
}

@media only screen and (min-width: 680.02px) {
  .promo-brand {
    width: 16.666%;
  }
  .promo-brand__text {
    padding-top: 0;
    font-size: 18px;
  }
  .promo-banner {
    padding-top: 25px;
  }
  .explore-deals-wrap .product-list-content {
    margin-bottom: 50px;
  }
  .explore-deals-wrap > div:last-of-type {
    padding-bottom: 20px;
  }
}

.promo-banner:first-of-type {
  margin-top: 0px;
}

.promo-banner__content-wrap {
  background-color: #f5f5f5;
  padding: 15px 20px;
}

.promo-banner__content-wrap a {
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
}

.promo-banner__content-wrap a::after {
  content: ">";
  padding-left: 5px;
}

.promo-banner__content {
  min-height: 100px;
}

.promo-banner__content h3 {
  font-size: 18px;
}

.product-list__header-link {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
}

.product-list__header-link::after {
  content: ">";
  padding-left: 5px;
}

.promo-banner__text,
.promo-grid__text {
  line-height: 1.4;
}

.promo-banner.md-12,
.promo-banner > .md-12 {
  margin-top: 15px;
  padding: 0 10px;
}

.promo-grid__content {
  padding: 30px;
}

.promo-assistance {
  text-align: center;
}

.promo-assistance a {
  font-weight: bold;
  color: inherit;
}

.promo-assistance .g-6 {
  padding: 10px 0;
  border-bottom: 2px solid #58595b;
}

.promo-assistance .g-6:first-of-type {
  border-top: 2px solid #58595b;
  margin-top: 40px;
}

@media only screen and (min-width: 680.02px) {
  .promo-assistance .g-6 {
    border-right: 2px solid #58595b;
    border-top: 2px solid #58595b;
    margin-top: 40px;
  }
  .promo-assistance .g-6:first-of-type {
    border-left: 2px solid #58595b;
  }
}

.promo-resources .row {
  margin-top: 20px;
}

.promo-resources p {
  font-size: 15px;
  font-weight: 700;
}

.promo-resources__flex {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 20px;
}

.promo-resources__img {
  padding-left: 0px;
  max-width: 40%;
  margin-bottom: -4px;
}

.promo-resources__block {
  padding-left: 20px;
}

.promo-resources__block a:after {
  content: ">";
  padding-left: 5px;
}

@media only screen and (min-width: 680.02px) {
  .promo-resources p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 820px) {
  .promo-resources p {
    font-size: 17px;
  }
}

.dev-message {
  max-width: 1000px;
  margin: 0 auto;
  padding: 5px 5px 0;
  color: #333333;
  background: white;
}

.dev-message strong {
  text-transform: uppercase;
  color: #d32121;
}

.multi-select {
  position: relative;
  z-index: 200;
  display: inline-block;
  min-width: 200px;
  max-width: 300px;
}

.multi-select__toggle {
  position: relative;
  display: block;
  width: 100%;
  padding: 5px 10px;
  font-family: inherit;
  font-size: 1em;
  text-align: left;
  background: #fff;
  border: 1px solid silver;
  border-radius: 3px;
}

.multi-select__toggle::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.multi-select__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.multi-select__list > li {
  padding: 0.25em;
  line-height: 1;
}

.multi-select__options {
  display: none;
  position: absolute;
  width: 100%;
  max-height: 10.25em;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.multi-select__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 199;
  background-color: transparent;
}

.multi-select label {
  display: block;
}

.multi-select--hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: 1px 1px 1px 3px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  left: 50%;
  top: 30px;
}

.register .multi-select {
  width: 100%;
  max-width: 100%;
}

.login-modal {
  display: none;
}

.login-modal__content {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 9000;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  margin: auto;
  padding: 30px 5px;
  transform: translateY(-50%);
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 15px black;
  overflow-y: auto;
}

.login-modal li {
  margin-bottom: 15px;
  overflow: hidden;
}

.login-modal li:last-child {
  margin-bottom: 0;
}

.register-modal-error > p {
  margin: 0;
  padding: 15px 0 0;
}

.form--half {
  float: left;
  width: 49%;
}

.form--half:first-child {
  margin-right: 1%;
}

.form--half:last-child {
  margin-left: 1%;
}

.fields--deactivated {
  opacity: 0.4;
  transition: opacity 0.3s;
}

.login-modal__img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media only screen and (max-width: 680px) {
  .login-modal {
    padding-top: 45px;
  }
  .login-modal__img {
    margin-bottom: 20px;
  }
}

.toggler__label {
  display: block;
  float: left;
  cursor: pointer;
}

.toggler__radio {
  position: absolute;
  left: -9999px;
}

.toggler__text {
  display: block;
  width: 100px;
  padding: 6px 14px;
  font-size: 0.85em;
  text-align: center;
  border: 1px solid #ddd;
  border-right: none;
}

.toggler__radio:checked + .toggler__text {
  color: #fff;
  background: #8d8d8a;
  border-color: #8d8d8a;
}

.toggler__label:first-child .toggler__text {
  border-radius: 3px 0 0 3px;
}

.toggler__label:last-child .toggler__text {
  border-right: 1px solid #ddd;
  border-radius: 0 3px 3px 0;
}

.or {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 0 15px;
}

.or::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 1px;
  margin: auto;
  background: #ddd;
}

.or__inner {
  display: inline-block;
  position: relative;
  z-index: 20;
  padding: 0 3px;
  background: #fff;
}

.autocomplete-suggestions {
  display: none;
  position: absolute;
  z-index: 9999;
  width: 85% !important;
  max-width: 350px;
  max-height: 254px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
}

.autocomplete-suggestion {
  position: relative;
  padding: 0.8em 0.6em;
  color: #333;
  font-size: 1.02em;
  line-height: 1.25;
  border-top: 1px solid #ddd;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-suggestion:first-child {
  border-top: none;
}

.autocomplete-suggestion b {
  color: #1f8dd6;
  font-weight: normal;
}

.autocomplete-suggestion.selected {
  background: #f0f0f0;
}

.autocomplete-prod-info {
  margin-top: 5px;
  font-size: 0.8em;
  overflow: hidden;
}

.autocomplete-sku {
  float: left;
}

.autocomplete-size {
  float: right;
}

.list-actions-left {
  float: none;
}

.list-management {
  margin-bottom: 15px;
}

.list-management__text {
  width: 100%;
  max-width: none;
  margin-top: 2px;
}

.list-management__actions {
  display: block;
}

.list-modal {
  position: relative;
  z-index: 10000;
}

.list-modal__actions {
  margin-top: 15px;
}

.list-modal .popover__content {
  max-width: 350px;
  padding: 10px 20px;
  text-align: left;
  background: white;
}

@media only screen and (min-width: 480px) {
  .list-actions-left {
    float: left;
  }
  .list-management {
    margin-bottom: 0;
  }
  .list-management__text {
    max-width: 175px;
  }
  .list-management__actions {
    display: inline;
  }
}

.slide {
  float: left;
  width: 320px;
  height: 320px;
}

.slide img {
  display: block;
  max-width: 100%;
  height: auto;
}

.js--insta-account {
  display: none;
}

.instagram-widget {
  max-width: 1000px;
  margin: 0 auto;
}

.instagram-widget__link {
  display: block;
  padding: 10px 0;
  font-size: 1.15em;
  font-weight: bold;
  text-align: right;
}

#instagram-widget .bx-controls-direction, #instagram-widget .bx-pager {
  display: none;
}

@media only screen and (min-width: 480px) {
  #instagram-widget {
    overflow: hidden;
    margin: 0 -2px;
  }
  .slide {
    width: 33.33333333%;
    height: auto;
    padding: 0 2px;
  }
}

@media only screen and (min-width: 820px) {
  .slide {
    width: 20%;
  }
}

.google-translate {
  margin: 0 auto;
}

.site-footer .google-translate {
  display: none;
}

@media only screen and (min-width: 820px) {
  .site-header .google-translate {
    padding-top: 40px;
  }
  .is--logged-in .site-header .google-translate {
    padding-top: 60px;
  }
  .translated-ltr .top-header,
  .translated-rtl .top-header {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 620px) {
  .site-header .google-translate {
    display: none;
  }
  .site-footer .google-translate {
    display: block;
    text-align: center;
  }
}

.gdpr-notice {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  color: #fff;
  font-size: 12px;
  background: #474747;
  transform: translateY(-100%);
  transition: transform 0.5s;
}

.gdpr-notice__inner {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px 45px 15px 15px;
}

@media only screen and (min-width: 681px) {
  .gdpr-notice {
    position: fixed;
    font-size: 14px;
  }
}

.gdpr-notice__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  padding: 0 0 4px;
  color: #333;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  background: #efefef;
  border: 1px solid #fff;
  border-radius: 100%;
  transition: background 0.3s;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

.gdpr-notice__close:hover {
  background: #cecece;
}

.gdpr-notice p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.gdpr-notice__link {
  color: inherit;
  text-decoration: underline;
  white-space: nowrap;
}

.gdpr-notice--active .gdpr-notice {
  transform: translateY(0);
}

.is--gdpr .container {
  transition: padding-top 0.5s;
}

.dialog-container, .dialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dialog-container {
  z-index: 9001;
  display: flex;
}

.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.6);
}

.dialog-content {
  position: relative;
  z-index: 2;
  align-self: center;
  margin: auto;
  background-color: #fff;
}

.dialog-container[aria-hidden="true"] {
  display: none;
}

.dialog-content {
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  padding: 25px;
  font-size: 18px;
  line-height: 1.5;
  overflow-y: auto;
}

.dialog-close {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 36px;
  height: 36px;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 36px;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
}

.dialog-close:hover {
  cursor: pointer;
}

.dialog-title {
  margin-bottom: 0.5em;
  padding-right: 35px;
}

.dialog-content p:last-child {
  margin-bottom: 0;
}

.dialog-content--topic {
  max-width: 900px;
  padding: 45px 0 0 0;
  border-radius: 2px;
  max-height: auto;
  overflow-y: hidden;
}

.topic-content {
  max-height: 80vh;
  padding: 0 20px 20px;
  overflow-y: auto;
}

.dialog-content--view-photo {
  max-width: 450px;
}

.dialog-content--view-photo .prod-info__name {
  font-size: 16px;
}

@media only screen and (min-width: 820px) {
  .dialog-content--view-photo .prod-info__name {
    font-size: 24px;
  }
}

.dialog-content--add-list {
  font-size: 16px;
}

.dialog-content--quick-view {
  max-width: 900px;
  font-size: 14px;
}

.fb_iframe_widget span {
  vertical-align: baseline !important;
}

.errorborder {
  border: 1px solid #d32121 !important;
  background-color: #d78d8d !important;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.bold {
  font-weight: bold;
}

.padd2 {
  padding: 2px;
}

.center {
  text-align: center;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-center {
  text-align: center !important;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.cf::after {
  content: '';
  display: table;
  clear: both;
}

.clear {
  clear: both;
}

.radrating {
  display: none !important;
  visibility: hidden !important;
}

.qty-break__button {
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
  color: #005ca0;
  font-family: inherit;
}

.qty-break__button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.qty-message {
  min-width: 200px;
  color: #fff;
  text-align: center;
}

.qty-message p {
  margin: 0;
  padding: 0 0 10px;
}

.qty-table {
  min-width: 200px;
  font-size: 11px;
  border: 1px solid #eee;
  border-collapse: collapse;
}

.qty-table__header {
  padding: 5px 10px;
  text-align: center;
  background: #eee;
  border: 1px solid #ddd;
}

.qty-table__cell {
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #ddd;
  background: #fff;
}

.pricing-notes {
  margin: 10px 0;
}

.pricing-notes__breaks {
  position: relative;
}

.pricing-notes__tooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  padding: 25px 10px 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  transform: translate(-25%, -100%);
}

.pricing-notes__tooltip::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -10px;
  margin-bottom: -20px;
  border: 20px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  border-bottom: 0;
  border-right: 0;
}

.pricing-notes__tooltip-close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background: none;
  border: none;
  color: #fff;
  line-height: 1;
  cursor: pointer;
}

@media only screen and (min-width: 420px) {
  .pricing-notes__tooltip {
    transform: translate(-65%, -100%);
  }
}

/*--- Lazy load images with animation ---*/
.lazy {
  opacity: 0;
  min-height: 1px;
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.is--visible {
  animation: visible 0.8s;
}

/*--- End Lazy load images with animation ---*/
.landing-password {
  max-width: 350px;
  margin: 60px auto;
  padding: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.landing-password__text {
  margin-bottom: 30px;
}

.landing-password__form {
  margin-top: 20px;
}

.landing-password__error {
  margin-bottom: 15px;
}

.landing-password__label {
  display: block;
  margin-bottom: 2px;
}

.landing-password__actions {
  margin-top: 10px;
}

.customer-pickup-container {
  display: grid;
  display: -ms-grid;
  justify-content: center;
  align-items: center;
}

.customer-pickup-container label {
  grid-column: 1;
  -ms-grid-column: 1;
}

.customer-pickup-container p {
  grid-column: 1;
  -ms-grid-column: 1;
  grid-row: 2;
  -ms-grid-row: 2;
}

.customer-pickup-container a {
  grid-column: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  height: 28px;
  grid-row: 1 / span 2;
}

.AdditionalContact {
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .customer-pickup-container a {
    grid-column: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-row: 3;
    margin-top: 10px;
    justify-self: baseline;
    margin-left: 0 !important;
  }
  .AdditionalContact {
    display: grid;
    grid-gap: 10px;
  }
  .AdditionalContact .coupon__button {
    margin-left: 0;
    justify-self: baseline;
  }
}

.preorder-warning {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  background-color: yellow;
}

.store-popup {
  z-index: 1000;
}

.store-popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.6);
}

.store-popup__box {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1100;
  width: 75%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0;
  color: #4c4c4c;
  background-color: #fff;
  border: 2px solid black;
  transform: translateY(-50%);
}

.store-popup__box .store-popup__title {
  margin: 0 0 5px;
  padding: 0;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
}

.store-popup__header {
  position: relative;
}

.store-popup__close {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 5px;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  transform: translateY(-50%);
  cursor: pointer;
}

.store-popup__page {
  min-height: 115px;
  max-height: 400px;
  overflow-y: auto;
}

.store-popup__page--confirm {
  max-height: 450px;
  font-size: 0.9em;
}

.pickup-store {
  padding: 20px;
  border-top: 1px solid #eee;
}

.pickup-store:first-child {
  border-top: none;
}

.pickup-store .pickup-store__name {
  margin: 0 0 10px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

.pickup-store__address {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.pickup-store__phone {
  margin: 5px 0;
}

.pickup-store__hours {
  margin: 5px 0;
}

.pickup-store__set {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 25px;
  color: #4c4c4c;
  font-size: 11px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.2s;
}

.pickup-store__set::hover {
  color: #000;
}

.pickup-store .icon--phone, .pickup-store .icon--clock {
  margin-right: 3px;
  vertical-align: top;
}

.icon--store {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-image: url("/images/store-white.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.9;
}

.shop-location__message {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 5px;
}

.shop-location__icon {
  grid-column: 1;
}

.shop-location__content {
  grid-column: 2;
  color: #fff;
}

.shop-location__text {
  display: inline;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: 1em;
  line-height: inherit;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.shop-location__text:hover {
  opacity: 0.8;
}

.store-popup__delivery {
  font-size: 0.9em;
}

.store-popup__inner {
  padding: 15px;
}

.delivery-subhead {
  margin: 0 0 0.5em;
  font-size: 1.15em;
}

.delivery-description {
  margin: 0 0 1.25em;
  padding: 0;
}

.delivery-form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
}

.delivery-form__action {
  grid-column: span 4;
  padding: 10px 0 5px;
}

.delivery-form__field {
  padding: 5px 0;
}

.delivery-form__field--half {
  grid-column: span 2;
}

.delivery-form__field--full {
  grid-column: span 4;
}

.delivery-field {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.delivery-or {
  position: relative;
  margin: 5px 45px;
  text-align: center;
}

.delivery-or span {
  position: relative;
  top: -1px;
  z-index: 80;
  display: inline-block;
  padding: 5px 25px;
  background-color: #fff;
  font-style: italic;
}

.delivery-or::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  display: block;
  height: 1px;
  background-color: #ddd;
}

.store-popup__page[aria-hidden="false"] {
  display: block;
}

.store-popup__page[aria-hidden="true"] {
  display: none;
}

.store-popup__header {
  padding: 0 20px 0 0;
  background-color: #fff;
  border-bottom: 5px solid black;
}

.store-popup__header--confirm {
  padding: 5px 20px 0 10px;
  background-color: black;
}

.store-popup__header--confirm .store-popup__close {
  color: #fff;
}

.store-popup-tabs__switch {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 8px 10px;
  font-family: inherit;
  font-size: 1em;
  line-height: 1;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.store-popup-tabs__switch--active {
  color: #fff;
  background-color: black;
}

.store-popup__close {
  margin-top: -0.1em;
  color: black;
  background-color: transparent;
}

.delivery-options[aria-hidden="true"], .delivery-results[aria-hidden="true"] {
  display: none;
}

.delivery-options[aria-hidden="false"], .delivery-results[aria-hidden="false"] {
  display: block;
}

.delivery-results__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.store-popup__new-address {
  display: inline-block;
  margin-left: auto;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  line-height: 1;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
}

.store-popup__new-address:hover {
  opacity: 0.7;
}

.delivery-noresults {
  padding-top: 0;
}

.delivery-noresults p {
  margin: 0 0 1.25em;
  padding: 0;
}

.delivery-noresults__actions {
  text-align: center;
}

.store-popup__errors[aria-hidden="true"] {
  display: none;
}

.store-popup__errors[aria-hidden="false"] {
  display: block;
}

.store-popup__error {
  color: #ff4136;
}

.label-error {
  color: #ff4136;
}

.store-popup__address, .store-popup__section {
  margin: 0 0 15px;
}

.store-popup__footer {
  padding: 15px;
  border: 1px solid #eee;
}

.store-popup__footer p {
  margin: 0 0 15px;
  padding: 0;
}

.--pending, .is--pending {
  position: relative;
  pointer-events: none;
}

.--pending::before, .is--pending::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.--pending::after, .is--pending::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 12px;
  border-radius: 100%;
  animation: circle 1s infinite ease;
}

.error-404 {
  max-width: 500px;
  margin: 60px auto;
  font-size: 18px;
  text-align: center;
}

.error-404__title {
  font-size: 48px;
}

.error-404__url {
  display: inline-block;
  background-color: #eee;
  padding: 2px 8px;
  border-radius: 2px;
}

.error-404__links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  font-size: 16px;
}

.error-404__link {
  display: inline-block;
  padding: 0 8px;
  text-align: left;
}

.error-404__link:nth-child(odd) {
  justify-self: end;
  border-right: 1px solid #aaa;
}

.error-404__link:nth-child(even) {
  padding-left: 9px;
}

.video-placeholder {
  position: relative;
  display: block;
  overflow: hidden;
}

.video-placeholder::before {
  content: '';
  display: block;
  padding-bottom: 56.25%;
}

.video-placeholder iframe, .video-placeholder embed, .video-placeholder object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-placeholder .video-placeholder__image {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: auto;
  border: none;
  cursor: pointer;
  transition: .4s all;
}

.video-placeholder__button {
  display: block;
  font-family: inherit;
  line-height: inherit;
  background-color: transparent;
  border: none;
}

.video-placeholder__button:hover .video-placeholder__image {
  filter: brightness(75%);
}

.video-placeholder__button:hover .play {
  background-color: black;
}

.play {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 72px;
  height: 72px;
  margin: auto;
  font-family: inherit;
  font-size: 2px;
  border: none;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: 105%;
  overflow: hidden;
  white-space: nowrap;
  transition: background-color 0.3s ease-out;
  transform: translateY(-50%);
}

.play::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 3px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 100%;
}

.play::after {
  content: '▶︎';
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  color: #fff;
  font-size: 32px;
  text-align: center;
  text-indent: 0;
  transform: translateY(-55%) translateX(3.5%);
}

.mp-checkout {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}

.mp-checkout .checkout-error {
  margin: 10px 0;
  padding: 0;
}

.mp-checkout__section {
  margin: 60px 0;
  font-size: 16px;
}

.mp-checkout__section:first-of-type {
  margin-top: 0;
}

.mp-checkout__header {
  margin-bottom: 0.25em;
  font-size: 28px;
  font-weight: 700;
}

.mp-checkout__subheader {
  margin-bottom: 0.25em;
  font-size: 1.15em;
  text-transform: uppercase;
}

.mp-checkout__grid {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.mp-checkout__box {
  margin: 15px 0;
  padding: 10px;
  line-height: 1.4;
  background-color: #fafafa;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

.mp-checkout__box.is--marketplace {
  border: 2px solid #31cd32;
}

.is--marketplace h3 {
  display: flex;
  align-items: center;
}

.is--marketplace h3 img {
  max-width: 24px;
}

.form-grid {
  margin: 30px 0 60px;
  max-width: 500px;
}

.form-grid .input {
  width: 100%;
}

.form-grid .label-block {
  margin-bottom: 5px;
}

.form-grid__mp-options {
  max-width: unset;
}

.form-grid__mp-options .form-grid__row:first-of-type {
  max-width: 500px;
}

[class^="form-grid__"] {
  margin-bottom: 5px;
}

@media only screen and (min-width: 480px) {
  .form-grid__row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 15px;
  }
}

.form-grid__12 {
  flex: 12;
}

.form-grid__11 {
  flex: 11;
}

.form-grid__10 {
  flex: 10;
}

.form-grid__9 {
  flex: 9;
}

.form-grid__8 {
  flex: 8;
}

.form-grid__7 {
  flex: 7;
}

.form-grid__6 {
  flex: 6;
}

.form-grid__5 {
  flex: 5;
}

.form-grid__4 {
  flex: 4;
}

.form-grid__3 {
  flex: 3;
}

.form-grid__2 {
  flex: 2;
}

.mp-checkout__main {
  flex: 9;
}

.mp-checkout__summary {
  max-width: 500px;
  padding: 15px;
  font-size: 16px;
  background-color: #fafafa;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

.mp-summary__header {
  margin-bottom: 30px;
  line-height: 1;
}

.mp-summary__header p {
  margin: 5px 0;
}

.mp-summary {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mp-summary__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4px 0;
}

.mp-summary__value {
  font-weight: bold;
}

.mp-summary__action {
  margin-top: 15px;
}

.mp-summary__action .button {
  display: block;
  width: 100%;
  border-radius: 0;
}

.mp-summary .mp-checkout__box.is--marketplace {
  padding: 5px;
}

.a-thing {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.the-right-side {
  position: sticky;
  top: 0;
}

@media only screen and (min-width: 800px) {
  .mp-checkout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 30px;
  }
  .mp-checkout__summary {
    flex: 3;
    align-self: flex-start;
    position: sticky;
    top: 140px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .mp-checkout {
    column-gap: 45px;
    padding-left: 0;
    padding-right: 0;
  }
}

.mp-checkout__education {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin: 5px 0;
}

@media only screen and (min-width: 680px) {
  .mp-checkout__education {
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .checkout-sku__label {
    display: inline-block;
  }
  .mp-checkout__education a {
    max-width: fit-content;
  }
}
